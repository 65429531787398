import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import AppRoutes from './AppRoutes';
import { ApiProvider } from './context/ApiContext';
import colors from './styles/_variables.scss';  // Import the SCSS variables
import './App.scss';  // Import the global styles for the app
import { LoaderProvider } from './context/LoaderContext';
import { SnackbarProvider } from './context/SnackbarContext';
import { No_Internet_Img } from './constants/constants';

// Function to set CSS variables from SCSS
const setCSSVariables = (colors: any) => {
  Object.keys(colors).forEach((key) => {
    document.documentElement.style.setProperty(`--${key}`, colors[key]);
  });
};

// Function to detect if the device is mobile or tablet
const isMobileOrTablet = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  // Check for mobile or tablet device based on the user-agent
  if (/android/i.test(userAgent) || /iPhone|iPad|iPod/.test(userAgent)) {
    return true; // It's a mobile or tablet
  }

  return false; // Otherwise, assume it's a desktop
};

function App() {
  const [themeType, setThemeType] = useState<'theme1' | 'theme2' | 'theme3'>('theme1'); // Default theme
  const [isMobile, setIsMobile] = useState(true); // State to check if it's mobile/tablet
  const [isOnline, setIsOnline] = useState(true); // State to track the internet connection status

  // Check if the user is on mobile or tablet
  useEffect(() => {
    if (!isMobileOrTablet()) {
      setIsMobile(false); // If not mobile or tablet, set the state to false
    }
  }, []);

  // Listen for changes in the network status
  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine); // Update the online state based on the browser's status
    };

    // Set the initial connection status
    setIsOnline(navigator.onLine);

    // Add event listeners for online and offline events
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // Clean up event listeners on unmount
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  // Disable right-click to prevent context menu
  useEffect(() => {
    const disableRightClick = (e: MouseEvent) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', disableRightClick);

    return () => {
      document.removeEventListener('contextmenu', disableRightClick);
    };
  }, []);

  // Disable developer tools shortcuts and keys
  useEffect(() => {
    const disableDevTools = (e: KeyboardEvent) => {
      // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, and Ctrl+U (view source)
      if (
        e.key === 'F12' ||
        (e.ctrlKey && e.shiftKey && e.key === 'I') ||
        (e.ctrlKey && e.shiftKey && e.key === 'J') ||
        (e.ctrlKey && e.key === 'U')
      ) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', disableDevTools);

    return () => {
      document.removeEventListener('keydown', disableDevTools);
    };
  }, []);

  // Check if developer tools are open
  // useEffect(() => {
  //   const detectDevTools = () => {
  //     const element = new Image();
  //     let devtoolsOpen = false;

  //     Object.defineProperty(element, 'id', {
  //       get: function () {
  //         devtoolsOpen = true;
  //       },
  //     });

  //     if (devtoolsOpen) {
  //       alert("Developer tools are open. Please close them to continue.");
  //       window.location.href = 'about:blank'; // Optionally, redirect to blank or log out
  //     }
  //   };

  //   // Check for devtools on an interval
  //   const devtoolsCheckInterval = setInterval(detectDevTools, 1000);

  //   return () => clearInterval(devtoolsCheckInterval); // Cleanup the interval
  // }, []);

  // Call this to update the theme type
  const handleSetTheme = (newTheme: string) => {
    setThemeType(newTheme as 'theme1' | 'theme2' | 'theme3');
  };

  // Dynamically apply the theme colors based on the current themeType
  const theme = createTheme({
    palette: {
      primary: {
        main: colors[`${themeType}Primary`], // Use SCSS variable based on themeType
      },
      secondary: {
        main: colors.secondary, // Use secondary color from SCSS or fallback
      },
      background: {
        default: colors.background, // Use background color from SCSS or fallback
      },
      error: {
        main: colors.error, // Use custom error color from SCSS
      }
    },
  });

  // Apply the CSS variables to the :root
  useEffect(() => {
    setCSSVariables(colors);  // Set global CSS variables for other components or custom CSS usage
  }, []);

  // If the user is on a desktop, show an error message
  if (!isMobile) {
    return (
      <div className="system_related_error">
        <div className="system_related_error_inner">
          <img
            src="/images/status/desktop.svg"
            alt="Desktop Not Supported"
          />
          <div className="text_part">
            <p className="head_text">Desktop Not Supported!</p>
            <p className="sub_text">
              This web app is designed for mobile or tablet. Please access it from your smartphone or tablet for the optimal experience.
            </p>
          </div>
        </div>
      </div>
    );
  }

  // If the user has lost internet connection, show a warning message
  if (!isOnline) {
    return (
      <div className="system_related_error no_internet_error">
        <div className="system_related_error_inner">
          <img
            src={No_Internet_Img}
            alt="No Internet Connection"
          />
          <div className="text_part">
            <p className="head_text">No Internet Connection</p>
            <p className="sub_text">
              It seems like your internet connection is lost. Please check your connection and try again.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ApiProvider>
        <SnackbarProvider>
          <LoaderProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppRoutes onSetTheme={handleSetTheme} />
            </ThemeProvider>
          </LoaderProvider>
        </SnackbarProvider>
      </ApiProvider>
    </>
  );
}

export default App;

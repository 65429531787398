import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer'; // Import MainContainer
import styles from './GlassSelection.module.scss'; // Import the CSS Module
import { useLoader } from '../../context/LoaderContext';
import CustomCheckbox from '../../components/CustomCheckbox';
import { useSnackbar } from '../../context/SnackbarContext';
import { arraysAreEqual, getSnackbarMessage } from '../../utilities/GenericService';
import { storageKey } from '../../constants/constants';
import api from '../../api/api';

// Define the type for a single claim
interface ClaimType {
  AREA: string;
  label: string;
  isChecked: boolean;
  overlayUrl: string;
}

const GlassSelection: React.FC = () => {
  const { token, apiResponse, setApiResponse } = useContext(ApiContext); // Access token, response, and setter from context
  const navigate = useNavigate();
  const [claimTypes, setClaimTypes] = useState<ClaimType[]>(apiResponse?.all_claim_types || []); // Initialize with claim types
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state
  const { id } = useParams<{ id: string }>(); // Get the id from the URL
  const { showLoader, hideLoader } = useLoader();
  const { openSnackbar } = useSnackbar();

  useEffect(() => {

    showLoader();
    if (!id) {
      // If id is missing, navigate to the error page
      navigate('/error', { replace: true });
      hideLoader();
      return;
    }

    // Only redirect to Journey if token or apiResponse is missing
    if (!token || !apiResponse) {
      hideLoader();
      navigate(`/${sessionStorage.getItem(storageKey.otp)}/?_id=${id}`, { replace: true });
    }
    hideLoader();
  }, [token, apiResponse, navigate]);

  // Handle checkbox toggle
  const handleCheckboxChange = (index: number) => {
    const updatedClaimTypes = claimTypes.map((claim, i) =>
      i === index ? { ...claim, isChecked: !claim.isChecked } : claim
    );
    setClaimTypes(updatedClaimTypes);
  };


  const handleFooterButtonClick = async () => {
    // Check if at least one checkbox is selected
    const hasSelectedClaims = claimTypes.some((claim) => claim.isChecked);

    if (!hasSelectedClaims) {
      openSnackbar(getSnackbarMessage(null, 'Please select at least one glass type to claim.'), "error", 4000);
      return; // Stop execution if no checkbox is selected
    }

    showLoader();
    setIsSubmitting(true); // Start submitting

    const selected_claim_subareas: string[] = claimTypes
      .filter((e) => e.isChecked)
      .map((e) => `${apiResponse.AREA}|${e.AREA}`) || [];



    // Check if selected_claim_subareas are equal to apiResponse.selected_claim_subareas
    if (arraysAreEqual(selected_claim_subareas || [], apiResponse?.claimed_areas || [])) {
      // Update the API context with the new claim types
      setApiResponse((prevResponse: any) => ({
        ...prevResponse,
        all_claim_types: claimTypes,
        selected_claim_subareas
      }));
      // Navigate to the next step
      navigate(`/damage-capture/${id}`);
    } else {
      try {
        const manual_upload_id = apiResponse?.manual_upload_id;
        const payload = {
          "claimedArea": selected_claim_subareas,
          "link_id": id,
          "preferred_location": apiResponse.browser_location,
          "save_as_draft": true
        }
        if (manual_upload_id) {
          // If manual_upload_id exists, update it with patchManualUpload
          try {
            await api.patchManualUpload(manual_upload_id, payload);
            setApiResponse((prevResponse: any) => ({
              ...prevResponse,
              all_claim_types: claimTypes,
              selected_claim_subareas
            }));
            // Navigate to the next step
            navigate(`/damage-capture/${id}`);
          } catch (err: any) {
            openSnackbar(getSnackbarMessage(err), "error", 4000);
          }
        } else {
          // Otherwise, initiate a new manual upload
          try {
            const response = await api.initiateManualUpload(payload);

            setApiResponse((prevResponse: any) => ({
              ...prevResponse,
              all_claim_types: claimTypes,
              selected_claim_subareas,
              manual_upload_id: response?.data?.trim()
            }));
            navigate(`/damage-capture/${id}`);
          } catch (err: any) {
            openSnackbar(getSnackbarMessage(err), "error", 4000);
          }
        }
      } catch (err: any) {
        openSnackbar(getSnackbarMessage(err), "error", 4000);
      } finally {
        hideLoader();
        setIsSubmitting(false); // Stop submitting
      }
    }
  };




  return (
    <MainContainer
      headerText="Select Areas Glass Damaged"
      showFooter={true}
      footerButtonLabel={isSubmitting ? 'Please wait...' : 'Start Now'}
      footerInfoText="Select multiple glass damage areas. keep car key handy to take interior pictures."
      onFooterButtonClick={handleFooterButtonClick}
    >
      <section className={styles.glass_body_outer}>
        {/* {error && <p className='custom_error'>{error}</p>} */}
        {claimTypes.length > 0 ? (
          <div className={styles.glass_body_inner}>
            {claimTypes.map((claim, index) => (
              <div className={styles.glass_data_body_outer} key={index}>
                <CustomCheckbox
                  checked={claim.isChecked}
                  onChange={() => handleCheckboxChange(index)}
                  label={
                    <div className={styles.glass_data_body_inner}>
                      <img src={claim.overlayUrl} alt={claim.label} />
                    </div>
                  }
                  id={`glass${index}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <p>No claim types available.</p>
        )}
      </section>
    </MainContainer>
  );
};

export default GlassSelection;

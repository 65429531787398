import React, { createContext, useContext, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

interface SnackbarContextType {
    openSnackbar: (message: string, severity: 'info' | 'error' | 'success', duration?: number) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

export const SnackbarProvider: React.FC<any> = ({ children }) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'info' | 'error' | 'success'>('info');
    const [snackbarDuration, setSnackbarDuration] = useState<number | undefined>();

    const openSnackbar = (message: string, severity: 'info' | 'error' | 'success', duration?: number) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
        setSnackbarDuration(duration);
    };

    const closeSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <SnackbarContext.Provider value={{ openSnackbar }}>
            {children}
            <Snackbar open={snackbarOpen} autoHideDuration={snackbarDuration} onClose={closeSnackbar} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert severity={snackbarSeverity} onClose={closeSnackbar}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};

import React, { ReactNode, useEffect } from 'react';
import Header from '../../Shared/Components/Header.tsx/Header';
import Footer from '../../Shared/Components/Footer.tsx/Footer';
import styles from './MainContainer.module.scss'; // Import the CSS Module
import { useLoader } from '../../context/LoaderContext';

interface MainContainerProps {
  children: ReactNode;
  headerText?: string;
  showFooter?: boolean;
  footerButtonLabel?: string;
  footerInfoText?: string;
  onFooterButtonClick?: () => void;
  isBack?: boolean; // For the back button in Header
  onBack?: () => void; // Function to handle back navigation
}

const MainContainer: React.FC<MainContainerProps> = ({
  children,
  headerText = '',
  showFooter = false,
  footerButtonLabel = '',
  footerInfoText = '',
  onFooterButtonClick = () => { },
  isBack = false, // Default to false for back button
  onBack
}) => {

  const { isLoading } = useLoader();  // Now, you can track loading state in the app

  useEffect(() => {

  }, []);

  return (
    <div className={`${styles.main_container_outer} ${isLoading ? 'app_loading' : ''}`} >
      <div className={styles.main_container_inner}>
        <div className={styles.main_container_header}>
          <Header
            headerText={headerText}
            isBack={isBack}    // Add back button condition
            onBack={onBack}    // Back navigation function
          />
        </div>
        <div className={styles.main_container_body_outer}>
          <div className={styles.main_container_body_inner}>
            {children}
          </div>
        </div>
        {showFooter && (
          <div className={styles.main_container_footer}>
            <div className={styles.main_container_footer_outer}>
              <Footer
                footerButtonLabel={footerButtonLabel}
                onFooterButtonClick={onFooterButtonClick}
                footerInfoText={footerInfoText}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MainContainer;

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MainContainer from './Pages/MainContainer/MainContainer';
import GlassSelection from './Pages/GlassSelection/GlassSelection';
import Journey from './Pages/Journey/Journey';  // Initial login-like page
import AuthGuard from './guard/AuthGuard';
import ErrorPage from './Pages/Error/Error';
import DamageCapture from './Pages/DamageCapture/DamageCapture';
import MandatoryImages from './Pages/MandatoryImages/MandatoryImages';
import Status from './Pages/Status/Status';

const AppRoutes: React.FC<{ onSetTheme: (theme: string) => void }> = ({ onSetTheme }) => {
    return (
        <Router>
            <Routes>
                <Route path="/:otp/*" element={<Journey onSetTheme={onSetTheme} />} />

                <Route
                    path="/glass-selection/:id"
                    element={
                        <AuthGuard>
                            <GlassSelection />
                        </AuthGuard>
                    }
                />

                <Route
                    path="/damage-capture/:id"
                    element={
                        <AuthGuard>
                            <DamageCapture />
                        </AuthGuard>
                    }
                />

                <Route
                    path="/mandatory-images/:id"
                    element={
                        <AuthGuard>
                            <MandatoryImages />
                        </AuthGuard>
                    }
                />

                <Route
                    path="/status/:id"
                    element={
                        <AuthGuard>
                            <Status />
                        </AuthGuard>
                    }
                />
                <Route path="/error" element={<ErrorPage />} />

                {/* Catch-all route for undefined paths */}
                <Route path="*" element={<Navigate to="/error" />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;

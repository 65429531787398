import React from 'react';

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // Accepts an event with the checkbox input
  label: string | JSX.Element; // Can be a string or JSX element
  id: string;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange, label, id }) => {
  return (
    <div>
      <input
        type="checkbox"
        checked={!!checked} // Ensure it's a boolean by coercing with !! to handle undefined
        onChange={onChange} // Pass the event handler correctly
        id={id}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default CustomCheckbox;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import ImageUploader from '../../Shared/Components/ImageUploader/ImageUploader';
import styles from './DamageCapture.module.scss'; // Import the CSS Module
import { useLoader } from '../../context/LoaderContext';
import CustomCheckbox from '../../components/CustomCheckbox';
import { getOTP, getSnackbarMessage } from '../../utilities/GenericService';
import { useSnackbar } from '../../context/SnackbarContext';
import { TAG_TYPE } from '../../constants/constants';
import { FileDetail as BaseFileDetail } from '../../constants/dataTypes';
import api from '../../api/api';
import CustomButton from '../../components/CustomButton';
import AddIcon from '@mui/icons-material/AddAPhoto';
import RemoveIcon from '@mui/icons-material/Delete';

// Extending the imported interface
interface FileDetail extends BaseFileDetail {
    is_minor?: boolean;  // Adding an optional field
}

interface GroupedFields {
    AREA: string;
    files: FileDetail[];
    minor_chip_damage: FileDetail[];
    is_minor_chip_checked?: boolean;
}

const DamageCapture: React.FC = () => {
    const { token, apiResponse, setApiResponse } = useContext(ApiContext);
    const navigate = useNavigate();
    const [groupedFields, setGroupedFields] = useState<GroupedFields[]>([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { id } = useParams<{ id: string }>(); // Get the id from the URL
    const { showLoader, hideLoader } = useLoader();
    const { openSnackbar } = useSnackbar();
    const maxMinorCount: number = 3;
    const scrollToAddButtonRef = useRef<HTMLDivElement | null>(null); // Reference for scrolling

    useEffect(() => {
        showLoader();
        if (!id) {
            navigate('/error', { replace: true });
            hideLoader();
            return;
        }
        if (!token || !apiResponse) {
            hideLoader();
            navigate(`/${getOTP()}/?_id=${id}`, { replace: true });
        } else {
            // Logic to group areas and files (adjusted to include minor_chip_damage)
            const selectedAreas = apiResponse.all_claim_types
                .filter((claimType: { AREA: string; isChecked: boolean }) => claimType.isChecked)
                .map((claimType: { AREA: string }) => claimType.AREA);

            // Safeguard: Ensure that file and its tags are defined
            let all_claimed_fields: FileDetail[] = apiResponse.claimed_fields || [];
            if (!all_claimed_fields?.length) {
                all_claimed_fields = apiResponse.file_details.filter((file: FileDetail) => file.is_claimed === true);
            }
            const selectedFields: FileDetail[] = all_claimed_fields.filter((file: FileDetail) => {
                if (!file || !file.tags) return false; // Skip undefined or invalid files
                const subAreaTag = file.tags.find((tag) => tag.tag_type === TAG_TYPE.SUB_AREA);
                return subAreaTag && selectedAreas.includes(subAreaTag.value);
            });

            selectedFields.forEach((file: FileDetail) => {
                // Check if this file matches an already added file based on template_body_value

                // Only attempt to update image_uri if it's missing in the current file
                if (!file.overlay_url) {
                    // Find the corresponding file in areaFiles with a matching template_body_value and overlay_url
                    const data = selectedFields.find(
                        (e) => e.template_body_value === file.template_body_value && e.overlay_url
                    );

                    // If a file with matching template_body_value and overlay_url is found, update the current file's overlay_url
                    if (data && data.overlay_url) {
                        file.overlay_url = data.overlay_url;
                    }
                }
            });

            const groupedByArea = selectedAreas.map((area: string) => {
                // Step 1: Filter all the files that belong to the current area
                const areaFiles: FileDetail[] = selectedFields.filter((file: FileDetail) =>
                    file?.tags?.find((tag) => tag.tag_type === TAG_TYPE.SUB_AREA && tag.value === area)
                );

                // Step 2: Group by wp_question_id and label, separate one as minor_chip_damage and others as regular files
                const files: FileDetail[] = [];
                let minorChipDamage: FileDetail[] = [];


                areaFiles.forEach((file: FileDetail) => {
                    // If the file is marked as minor_chip_damage by the backend
                    if (file.is_minor_damage) {
                        // Assign it as the minor chip damage
                        // minorChipDamage = { ...file };
                        // file.wp_question_id = `${file.wp_question_id}_minor_${minorChipDamage?.length}`
                        if (!file.wp_question_id.includes('_minor_')) {
                            file.wp_question_id = `${file.wp_question_id}_minor_${minorChipDamage.length}`;
                        }
                        if (!file.additional_image_id) {
                            file.additional_image_id = '0';
                        }
                        // file.additional_image_id = file.wp_question_id;
                        minorChipDamage?.push({ ...file });
                    } else {
                        // Otherwise, it's a regular file, so add it to the files array
                        files.push({ ...file });
                    }
                });

                // Step 3: Sort the files based on the template_body_value
                const sortedFiles = files.sort((a: FileDetail, b: FileDetail) => {
                    // Sort based on conditions
                    const aValue = a?.template_body_value?.toLowerCase();
                    const bValue = b?.template_body_value?.toLowerCase();

                    if (aValue?.includes("outside")) return -1; // "outside" comes first
                    if (bValue?.includes("outside")) return 1;

                    if (aValue?.includes("driver side") && !bValue?.includes("driver side")) return -1; // "driver side" comes next
                    if (bValue?.includes("driver side") && !aValue?.includes("driver side")) return 1;

                    if (aValue?.includes("passenger side") && !bValue?.includes("passenger side")) return -1; // "passenger side" comes next
                    if (bValue?.includes("passenger side") && !aValue?.includes("passenger side")) return 1;

                    // If no match, keep the original order
                    return 0;
                });

                return {
                    AREA: area,
                    files: sortedFiles, // Regular files without duplicates
                    minor_chip_damage: minorChipDamage || null, // Separate minor chip damage if it exists
                    // is_minor_chip_checked: !!(minorChipDamage as unknown as FileDetail)?.image_uri, // Explicit type assertion
                    is_minor_chip_checked: minorChipDamage.some(file => !!file.image_uri) // Check if any minor chip has an image_uri
                };
            });
            setGroupedFields(groupedByArea);
            hideLoader();
        }
    }, [token, apiResponse, navigate]);


    // Handle checkbox toggle for minor chip damage
    // const handleMinorChipDamageToggle = (event: React.ChangeEvent<HTMLInputElement>, groupArea: string) => {
    //     const isChecked = event.target.checked; // Get whether the checkbox is checked or not

    //     const updatedGroupedFields = groupedFields.map((group) => {
    //         if (group.AREA === groupArea && group.minor_chip_damage) {
    //             const updatedMinorChipDamage = { ...group.minor_chip_damage };

    //             if (!isChecked) {
    //                 // If unchecked and image exists, delete the image
    //                 if (updatedMinorChipDamage.image_uri) {
    //                     handleDeleteImage(updatedMinorChipDamage.wp_question_id, true);
    //                 }
    //                 return { ...group, is_minor_chip_checked: false }; // Uncheck the checkbox
    //             } else {
    //                 // If checked, mark it as true
    //                 return { ...group, is_minor_chip_checked: true }; // Check the checkbox
    //             }
    //         }
    //         return group;
    //     });

    //     setGroupedFields(updatedGroupedFields);
    // };


    // const handleMinorChipDamageToggle = (event: React.ChangeEvent<HTMLInputElement>, groupArea: string) => {
    //     const isChecked = event.target.checked; // Get whether the checkbox is checked or not

    //     const updatedGroupedFields = groupedFields.map((group) => {
    //         if (group.AREA === groupArea && group.minor_chip_damage) {
    //             // Update all items in the minor_chip_damage array
    //             const updatedMinorChipDamage = group.minor_chip_damage.map((chip) => {
    //                 if (!isChecked) {
    //                     // If unchecked and image exists, delete the image for each chip
    //                     if (chip.image_uri) {
    //                         handleDeleteImage(chip.wp_question_id, true);
    //                     }
    //                     return { ...chip, image_uri: null }; // Clear the image URI
    //                 } else {
    //                     return chip; // Keep the existing state if checked
    //                 }
    //             });

    //             return {
    //                 ...group,
    //                 minor_chip_damage: updatedMinorChipDamage,
    //                 is_minor_chip_checked: isChecked, // Update the checked state for the group
    //             };
    //         }
    //         return group;
    //     });

    //     setGroupedFields(updatedGroupedFields);
    // };



    // const handleMinorChipDamageToggle = async (event: React.ChangeEvent<HTMLInputElement>, groupArea: string) => {
    //     const isChecked = event.target.checked; // Get whether the checkbox is checked or not

    //     const updatedGroupedFields = await Promise.all(
    //         groupedFields.map(async (group) => {
    //             if (group.AREA === groupArea && group.minor_chip_damage) {
    //                 // If unchecked, remove all but the first minor chip damage and clear images
    //                 const updatedMinorChipDamage = isChecked
    //                     ? group.minor_chip_damage // Keep all items if checked
    //                     : [{ ...group.minor_chip_damage[0], image_uri: null }]; // Keep only the first item with image URI cleared if unchecked

    //                 // Delete images for the rest of the items if unchecked
    //                 if (!isChecked) {
    //                     const deletePromises = group.minor_chip_damage.slice(1).map((chip) => {
    //                         if (chip.image_uri) {
    //                             return handleDeleteImage(chip.wp_question_id, true);
    //                         }
    //                         return Promise.resolve();
    //                     });
    //                     await Promise.all(deletePromises);
    //                 }

    //                 return {
    //                     ...group,
    //                     minor_chip_damage: updatedMinorChipDamage, // Update with only one minor chip if unchecked
    //                     is_minor_chip_checked: isChecked, // Update the checked state for the group
    //                 };
    //             }
    //             return group;
    //         })
    //     );

    //     setGroupedFields(updatedGroupedFields);
    // };


    // const handleMinorChipDamageToggle = async (event: React.ChangeEvent<HTMLInputElement>, groupArea: string) => {
    //     const isChecked = event.target.checked; // Get whether the checkbox is checked or not

    //     const updatedGroupedFields = await Promise.all(
    //         groupedFields.map(async (group) => {
    //             if (group.AREA === groupArea && group.minor_chip_damage) {
    //                 // If unchecked, remove all but the first minor chip damage and clear images
    //                 const updatedMinorChipDamage = isChecked
    //                     ? group.minor_chip_damage // Keep all items if checked
    //                     : [{ ...group.minor_chip_damage[0], image_uri: null }]; // Keep only the first item with image URI cleared if unchecked

    //                 // Delete images for minor chips except the first one if unchecked
    //                 if (!isChecked) {
    //                     // const deletePromises = group.minor_chip_damage.slice(0).map((chip) => {
    //                     //     if (chip.image_uri) {
    //                     //         return handleDeleteImage(chip.wp_question_id, true); // Only call delete if image_uri exists
    //                     //     }
    //                     //     return Promise.resolve(); // Skip delete if no image_uri
    //                     // });
    //                     // await Promise.all(deletePromises);
    //                     await handleDeleteImage(group.minor_chip_damage[0].wp_question_id, true, true);
    //                 }

    //                 return {
    //                     ...group,
    //                     minor_chip_damage: updatedMinorChipDamage, // Update with only one minor chip if unchecked
    //                     is_minor_chip_checked: isChecked, // Update the checked state for the group
    //                 };
    //             }
    //             return group;
    //         })
    //     );

    //     setGroupedFields(updatedGroupedFields);
    // };






    // Handle base64 preview
    // const handleBase64Preview = (wp_question_id: string, base64String: string | null) => {
    //     const updatedGroupedFields = groupedFields.map((group) => ({
    //         ...group,
    //         files: group.files.map((file) =>
    //             file.wp_question_id === wp_question_id ? { ...file, image_uri: base64String } : file
    //         ),
    //         minor_chip_damage: group.minor_chip_damage?.wp_question_id === wp_question_id
    //             ? { ...group.minor_chip_damage, image_uri: base64String }
    //             : group.minor_chip_damage,
    //     }));
    //     setGroupedFields(updatedGroupedFields);
    // };


    const handleBase64Preview = (wp_question_id: string, base64String: string | null) => {
        const updatedGroupedFields = groupedFields.map((group) => ({
            ...group,
            files: group.files.map((file) =>
                file.wp_question_id === wp_question_id ? { ...file, image_uri: base64String } : file
            ),
            minor_chip_damage: group.minor_chip_damage
                ? group.minor_chip_damage.map((chip) =>
                    chip.wp_question_id === wp_question_id ? { ...chip, image_uri: base64String } : chip
                )
                : [],
        }));
        setGroupedFields(updatedGroupedFields);
    };




    // const handleUploadImage = async (wp_question_id: string, file: File, isMinor?: boolean) => {
    //     showLoader();
    //     try {
    //         const formData = new FormData();
    //         formData.append('file', file, file.name); // Append the file

    //         // Find the file data that corresponds to the given `id`
    //         const updatedData = apiResponse.claimed_fields.find((e: FileDetail) => e.wp_question_id === wp_question_id);

    //         if (!updatedData) {
    //             throw new Error('File data not found for the given ID');
    //         }

    //         // Append the JSON payload as a string
    //         formData.append('request', JSON.stringify(updatedData));

    //         // Make the API call to upload the image
    //         await api.uploadImage(apiResponse.manual_upload_id, formData);

    //         // Convert the file to base64 for preview
    //         const base64String = await convertToBase64(file);

    //         // Update the grouped fields based on whether the uploaded image is for a minor chip or a regular file
    //         const updatedGroupedFields = groupedFields.map((group) => ({
    //             ...group,
    //             files: !isMinor
    //                 ? group.files.map((fileDetail) =>
    //                     fileDetail.wp_question_id === wp_question_id
    //                         ? { ...fileDetail, image_uri: base64String ?? null } // Update the regular file image_uri
    //                         : fileDetail
    //                 )
    //                 : group.files, // Keep regular files unchanged for minor chips
    //             minor_chip_damage: isMinor && group.minor_chip_damage?.wp_question_id === wp_question_id
    //                 ? { ...group.minor_chip_damage, image_uri: base64String } // Update minor chip damage image_uri
    //                 : group.minor_chip_damage,
    //         }));

    //         setGroupedFields(updatedGroupedFields);

    //         // Flatten the grouped fields, making sure minor chip damages are included
    //         const updatedFields = updatedGroupedFields.flatMap((group) => [
    //             ...group.files,
    //             ...(group.minor_chip_damage ? [group.minor_chip_damage] : [])
    //         ]);

    //         // Update the API context with the updated fields
    //         updateApiContext(updatedFields);

    //     } catch (err) {
    //         // Display an error message and clear the base64 preview
    //         openSnackbar(getSnackbarMessage(err), "error", 4000);
    //         handleBase64Preview(wp_question_id, null);
    //     } finally {
    //         hideLoader();
    //     }
    // };


    const handleUploadImage = async (wp_question_id: string, file: File, isMinor?: boolean) => {
        showLoader();
        try {
            const formData = new FormData();
            formData.append('file', file, file.name); // Append the file

            // Find the file data that corresponds to the given `wp_question_id`
            // const updatedData: FileDetail | null = apiResponse.claimed_fields.find((e: FileDetail) => e.wp_question_id === wp_question_id);
            const updatedData: FileDetail | null = apiResponse.claimed_fields.find((e: FileDetail) => e.wp_question_id === wp_question_id);
            if (!updatedData) {
                throw new Error('File data not found for the given ID');
            }

            // updatedData.wp_question_id = updatedData.wp_question_id.replace(/_minor_\d+$/, '');

            const data = JSON.parse(JSON.stringify(updatedData));
            if (isMinor) {
                // data.additional_image_id = updatedData.wp_question_id;
                data.wp_question_id = updatedData.wp_question_id.replace(/_minor_\d+$/, '');
            }

            // Append the JSON payload as a string
            // formData.append('request', JSON.stringify(updatedData));
            formData.append('request', JSON.stringify(data));

            // Make the API call to upload the image
            await api.uploadImage(apiResponse.manual_upload_id, formData);

            // Convert the file to base64 for preview
            const base64String = await convertToBase64(file);

            // Update the grouped fields based on whether the uploaded image is for a minor chip or a regular file
            const updatedGroupedFields = groupedFields.map((group) => ({
                ...group,
                files: !isMinor
                    ? group.files.map((fileDetail) =>
                        fileDetail.wp_question_id === wp_question_id
                            ? { ...fileDetail, image_uri: base64String ?? null } // Update the regular file image_uri
                            : fileDetail
                    )
                    : group.files, // Keep regular files unchanged for minor chips
                minor_chip_damage: isMinor
                    ? group.minor_chip_damage?.map((chip) =>
                        chip.wp_question_id === wp_question_id
                            ? { ...chip, image_uri: base64String ?? null } // Update the minor chip damage image_uri
                            : chip
                    )
                    : group.minor_chip_damage,
            }));

            setGroupedFields(updatedGroupedFields);

            // Flatten the grouped fields, ensuring that all `minor_chip_damage` items are included
            const updatedFields = updatedGroupedFields.flatMap((group) => [
                ...group.files,
                ...(group.minor_chip_damage ? group.minor_chip_damage : [])
            ]);

            // Update the API context with the updated fields
            updateApiContext(updatedFields);

        } catch (err) {
            // Display an error message and clear the base64 preview
            openSnackbar(getSnackbarMessage(err), "error", 4000);
            handleBase64Preview(wp_question_id, null);
        } finally {
            hideLoader();
        }
    };





    // const handleDeleteImage = async (wp_question_id: string, isMinor?: boolean) => {
    //     showLoader();

    //     const previousImageUri = !isMinor
    //         ? groupedFields.flatMap((group) => group.files).find((fileDetail) => fileDetail.wp_question_id === wp_question_id)?.image_uri ?? null
    //         : groupedFields.find((group) => group.minor_chip_damage?.wp_question_id === wp_question_id)?.minor_chip_damage?.image_uri ?? null;

    //     try {
    //         // Mock API request for deleting the image
    //         await api.deleteImage(apiResponse.manual_upload_id, wp_question_id);

    //         const updatedGroupedFields = groupedFields.map((group) => {
    //             // Handle deletion for regular files
    //             const updatedFiles = !isMinor
    //                 ? group.files.map((fileDetail) =>
    //                     fileDetail.wp_question_id === wp_question_id ? { ...fileDetail, image_uri: null } : fileDetail
    //                 )
    //                 : group.files; // No changes if deleting minor chip

    //             // Handle deletion for minor chip damage
    //             const updatedMinorChipDamage = isMinor && group.minor_chip_damage?.wp_question_id === wp_question_id
    //                 ? { ...group.minor_chip_damage, image_uri: null }
    //                 : group.minor_chip_damage;

    //             // Return the updated group
    //             return {
    //                 ...group,
    //                 files: updatedFiles,
    //                 minor_chip_damage: updatedMinorChipDamage,
    //             };
    //         });

    //         // Update local state with the modified grouped fields
    //         setGroupedFields(updatedGroupedFields);

    //         // Ensure minor_chip_damage is only concatenated if it exists
    //         const updatedFields = updatedGroupedFields.flatMap((group) => [
    //             ...group.files,
    //             ...(group.minor_chip_damage ? [group.minor_chip_damage] : []),
    //         ]);

    //         // Update the API context
    //         updateApiContext(updatedFields);
    //     } catch (err) {
    //         openSnackbar(getSnackbarMessage(err), "error", 4000);
    //         // Revert the image preview to the previous URI in case of failure
    //         handleBase64Preview(wp_question_id, previousImageUri);
    //     } finally {
    //         hideLoader();
    //     }
    // };




    // const handleDeleteImage = async (wp_question_id: string, isMinor?: boolean) => {
    //     showLoader();

    //     // Find the previous image URI before deletion
    //     const previousImageUri = !isMinor
    //         ? groupedFields.flatMap((group) => group.files).find((fileDetail) => fileDetail.wp_question_id === wp_question_id)?.image_uri ?? null
    //         : groupedFields.flatMap((group) => group.minor_chip_damage || []).find((chip) => chip.wp_question_id === wp_question_id)?.image_uri ?? null;

    //     try {
    //         // Mock API request for deleting the image
    //         let wp_id: string = wp_question_id;
    //         if (isMinor) {
    //             wp_id = wp_question_id.replace(/_minor_\d+$/, '');
    //             const updatedData: FileDetail | null = apiResponse.claimed_fields.find((e: FileDetail) => e.wp_question_id === wp_question_id);

    //             if (!updatedData) {
    //                 throw new Error('File data not found for the given ID');
    //             }
    //             await api.deleteImage(apiResponse.manual_upload_id, wp_id, updatedData.additional_image_id);
    //         } else {
    //             await api.deleteImage(apiResponse.manual_upload_id, wp_id);
    //         }
    //         let deletableImages: FileDetail[] = [];
    //         let data: FileDetail | null = null;

    //         const updatedGroupedFields = groupedFields.map((group) => {
    //             // Handle deletion for regular files
    //             const updatedFiles = !isMinor
    //                 ? group.files.map((fileDetail) =>
    //                     fileDetail.wp_question_id === wp_question_id ? { ...fileDetail, image_uri: null } : fileDetail
    //                 )
    //                 : group.files; // Keep files unchanged if deleting minor chip

    //             // Handle deletion for minor chip damage files
    //             const updatedMinorChipDamage = isMinor
    //                 ? group.minor_chip_damage?.map((chip) =>
    //                     chip.wp_question_id === wp_question_id ? { ...chip, image_uri: null } : chip
    //                 ) ?? []
    //                 : group.minor_chip_damage;



    //             if (isMinor) {
    //                 // Get all items with `null` image_uri in minor chip damage
    //                 // const noImageEntries = updatedMinorChipDamage.filter((chip: { image_uri: any; }) => !chip.image_uri);

    //                 if (updatedMinorChipDamage.length === 1) {
    //                     data = { ...updatedMinorChipDamage[0] }
    //                 }
    //                 // Remove `null` image_uri items if there's more than one in the array
    //                 updatedMinorChipDamage.forEach((entry, index) => {
    //                     // if (updatedMinorChipDamage.length > 1) {
    //                     // const index = updatedMinorChipDamage.findIndex(
    //                     //     (chip: { wp_question_id: any; }) => chip.wp_question_id === entry.wp_question_id
    //                     // );
    //                     // if (index !== -1) {
    //                     if (!entry.image_uri) {
    //                         deletableImages.push(updatedMinorChipDamage[index]);
    //                         updatedMinorChipDamage.splice(index, 1);
    //                     }

    //                     // }
    //                     // }
    //                 });

    //                 console.log('deletableImages', deletableImages);
    //             }
    //             let updatedMinorChipDamage2 = updatedMinorChipDamage
    //             if (data) {
    //                 updatedMinorChipDamage2 = { ...updatedMinorChipDamage, ...data }
    //             }


    //             // Return the updated group
    //             return {
    //                 ...group,
    //                 files: updatedFiles,
    //                 minor_chip_damage: updatedMinorChipDamage,
    //                 // minor_chip_damage: updatedMinorChipDamage2,
    //             };
    //         });

    //         // Update local state with the modified grouped fields
    //         setGroupedFields(updatedGroupedFields);

    //         // Ensure minor_chip_damage is only concatenated if it exists
    //         const updatedFields = updatedGroupedFields.flatMap((group) => [
    //             ...group.files,
    //             ...(group.minor_chip_damage ? group.minor_chip_damage : []),
    //         ]);

    //         // Update the API context with the updated fields
    //         updateApiContext(updatedFields, deletableImages, data);

    //     } catch (err) {
    //         openSnackbar(getSnackbarMessage(err), "error", 4000);
    //         // Revert the image preview to the previous URI in case of failure
    //         handleBase64Preview(wp_question_id, previousImageUri);
    //     } finally {
    //         hideLoader();
    //     }
    // };


    const handleDeleteImage = async (wp_question_id: string, isMinor?: boolean) => {
        showLoader();

        // Find the previous image URI before deletion
        const previousImageUri = !isMinor
            ? groupedFields.flatMap((group) => group.files).find((fileDetail) => fileDetail.wp_question_id === wp_question_id)?.image_uri ?? null
            : groupedFields.flatMap((group) => group.minor_chip_damage || []).find((chip) => chip.wp_question_id === wp_question_id)?.image_uri ?? null;

        try {
            // Mock API request for deleting the image
            let wp_id: string = wp_question_id;
            if (isMinor) {
                wp_id = wp_question_id.replace(/_minor_\d+$/, '');
                const updatedData: FileDetail | null = apiResponse.claimed_fields.find((e: FileDetail) => e.wp_question_id === wp_question_id);

                if (!updatedData) {
                    throw new Error('File data not found for the given ID');
                }

                await api.deleteImage(apiResponse.manual_upload_id, wp_id, updatedData.additional_image_id);

            } else {
                await api.deleteImage(apiResponse.manual_upload_id, wp_id);
            }

            const updatedGroupedFields = groupedFields.map((group) => {
                // Handle deletion for regular files
                const updatedFiles = !isMinor
                    ? group.files.map((fileDetail) =>
                        fileDetail.wp_question_id === wp_question_id ? { ...fileDetail, image_uri: null } : fileDetail
                    )
                    : group.files; // Keep files unchanged if deleting minor chip

                // Handle deletion for minor chip damage files
                const updatedMinorChipDamage = isMinor
                    ? group.minor_chip_damage?.map((chip) =>
                        chip.wp_question_id === wp_question_id ? { ...chip, image_uri: null } : chip
                    ) ?? []
                    : group.minor_chip_damage;

                // Return the updated group
                return {
                    ...group,
                    files: updatedFiles,
                    minor_chip_damage: updatedMinorChipDamage
                };
            });

            // Update local state with the modified grouped fields
            setGroupedFields(updatedGroupedFields);

            // Ensure minor_chip_damage is only concatenated if it exists
            const updatedFields = updatedGroupedFields.flatMap((group) => [
                ...group.files,
                ...(group.minor_chip_damage ? group.minor_chip_damage : []),
            ]);

            // Update the API context with the updated fields
            updateApiContext(updatedFields);

        } catch (err) {
            openSnackbar(getSnackbarMessage(err), "error", 4000);
            // Revert the image preview to the previous URI in case of failure
            handleBase64Preview(wp_question_id, previousImageUri);
        } finally {
            hideLoader();
        }
    };





    // const updateApiContext = (updatedFields: FileDetail[]) => {
    //     // Update existing claimed_fields based on wp_question_id (id) uniqueness
    //     const updatedClaimedFields = apiResponse.claimed_fields.map((existingField: FileDetail) => {
    //         // Find the corresponding updated field by wp_question_id
    //         const updatedField = updatedFields.find((newField) => newField.wp_question_id === existingField.wp_question_id);

    //         // Replace the existing field with the updated field if found, otherwise keep the existing field
    //         return updatedField ? updatedField : existingField;
    //     });



    //     // Add any new fields from updatedFields that are not already in claimed_fields
    //     updatedFields.forEach((newField) => {
    //         const isFieldExists = updatedClaimedFields.some(
    //             (existingField: FileDetail) => existingField.wp_question_id === newField.wp_question_id
    //         );

    //         // If the field doesn't exist in claimed_fields, add it
    //         if (!isFieldExists) {
    //             updatedClaimedFields.push(newField);
    //         }
    //     });

    //     // Update the context with the newly constructed claimed_fields
    //     setApiResponse({
    //         ...apiResponse,
    //         claimed_fields: updatedClaimedFields,
    //     });
    // };



    const updateApiContext = (updatedFields: FileDetail[], excludeWpQuestionId?: string) => {
        // Update existing claimed_fields based on wp_question_id (id) uniqueness
        const updatedClaimedFields = apiResponse.claimed_fields
            .filter((existingField: FileDetail) => existingField.wp_question_id !== excludeWpQuestionId) // Exclude the field to delete
            .map((existingField: FileDetail) => {
                // Find the corresponding updated field by wp_question_id
                const updatedField = updatedFields.find((newField) => newField.wp_question_id === existingField.wp_question_id);

                // Replace the existing field with the updated field if found, otherwise keep the existing field
                return updatedField ? updatedField : existingField;
            });

        // Add any new fields from updatedFields that are not already in claimed_fields and not the excluded one
        updatedFields.forEach((newField) => {
            const isFieldExists = updatedClaimedFields.some(
                (existingField: FileDetail) => existingField.wp_question_id === newField.wp_question_id
            );

            // If the field doesn't exist in claimed_fields and is not excluded, add it
            if (!isFieldExists && newField.wp_question_id !== excludeWpQuestionId) {
                updatedClaimedFields.push(newField);
            }
        });

        // Update the context with the newly constructed claimed_fields
        setApiResponse({
            ...apiResponse,
            claimed_fields: updatedClaimedFields,
        });
    };




    // const updateApiContext = (updatedFields: FileDetail[], deletableImages?: FileDetail[], data?: FileDetail | null) => {
    //     // Update existing claimed_fields based on wp_question_id (id) uniqueness
    //     const updatedClaimedFields = apiResponse.claimed_fields.map((existingField: FileDetail) => {
    //         // Find the corresponding updated field by wp_question_id
    //         const updatedField = updatedFields.find((newField) => newField.wp_question_id === existingField.wp_question_id);

    //         // Replace the existing field with the updated field if found, otherwise keep the existing field
    //         return updatedField ? updatedField : existingField;
    //     });

    //     // Add any new fields from updatedFields that are not already in claimed_fields
    //     updatedFields.forEach((newField) => {
    //         const isFieldExists = updatedClaimedFields.some(
    //             (existingField: FileDetail) => existingField.wp_question_id === newField.wp_question_id
    //         );

    //         // If the field doesn't exist in claimed_fields, add it
    //         if (!isFieldExists) {
    //             updatedClaimedFields.push(newField);
    //         }
    //     });

    //     // Remove files from updatedClaimedFields that match wp_question_id in deletableImages
    //     let filteredClaimedFields = deletableImages
    //         ? updatedClaimedFields.filter(
    //             (field: { wp_question_id: string; }) => !deletableImages.some(
    //                 (deletable) => deletable.wp_question_id === field.wp_question_id
    //             )
    //         )
    //         : updatedClaimedFields;

    //     if (data) {
    //         filteredClaimedFields = { ...filteredClaimedFields, ...data }
    //     }

    //     // Update the context with the newly constructed claimed_fields
    //     setApiResponse({
    //         ...apiResponse,
    //         claimed_fields: filteredClaimedFields,
    //     });
    // };







    const convertToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };



    // const handleFooterButtonClick = async () => {
    //     setIsSubmitting(true); // Start submitting
    //     // try {
    //     // Validate if all claimed fields and checked minor chip damages have images
    //     const missingImages = groupedFields.flatMap((group) => {
    //         // Check for files without image
    //         const missingFiles = group.files.filter((file) => !file.image_uri);

    //         // If minor chip damage is checked, check if the image is uploaded
    //         const missingMinorChipImage = group.is_minor_chip_checked && !group.minor_chip_damage?.image_uri
    //             ? [group.minor_chip_damage]
    //             : [];

    //         return [...missingFiles, ...missingMinorChipImage];
    //     });

    //     if (missingImages.length > 0) {
    //         // Show an error and prevent submission if images are missing
    //         // setError('Please upload images for all claimed fields and selected minor chip damages before proceeding.');
    //         openSnackbar(getSnackbarMessage(null, "Please upload images for all claimed fields and selected minor chip damages before proceeding."), "error", 4000);
    //         setIsSubmitting(false); // Stop submitting
    //         return; // Stop execution if validation fails
    //     } else {
    //         // setError(null);
    //     }
    //     showLoader();
    //     // If successful, navigate to the next page
    //     navigate(`/mandatory-images/${id}`);
    //     hideLoader();
    // };



    // const handleFooterButtonClick = async () => {
    //     setIsSubmitting(true); // Start submitting

    //     // Validate if all claimed fields and checked minor chip damages have images
    //     const missingImages = groupedFields.flatMap((group) => {
    //         // Check for regular files without image
    //         const missingFiles = group.files.filter((file) => !file.image_uri);

    //         // Check if any checked minor chip damages are missing an image
    //         const missingMinorChipImages = group.is_minor_chip_checked
    //             ? group.minor_chip_damage?.filter((chip) => !chip.image_uri) || []
    //             : [];

    //         return [...missingFiles, ...missingMinorChipImages];
    //     });

    //     if (missingImages.length > 0) {
    //         // Show an error and prevent submission if images are missing
    //         openSnackbar(
    //             getSnackbarMessage(null, "Please upload images for all claimed fields and selected minor chip damages before proceeding."),
    //             "error",
    //             4000
    //         );
    //         setIsSubmitting(false); // Stop submitting
    //         return; // Stop execution if validation fails
    //     }

    //     // If all images are present, proceed with submission
    //     showLoader();
    //     // Navigate to the next page
    //     navigate(`/mandatory-images/${id}`);
    //     hideLoader();
    // };



    const handleFooterButtonClick = async () => {
        setIsSubmitting(true); // Start submitting

        // Validate if all claimed fields and checked minor chip damages have images
        const missingImages = groupedFields.flatMap((group) => {
            // Check for regular files without image
            const missingFiles = group.files.filter((file) => !file.image_uri);

            // If minor chip is checked, ensure at least one minor chip damage has an image
            const isMinorChipValid = group.is_minor_chip_checked
                ? group.minor_chip_damage.some((chip) => chip.image_uri) // At least one image is required if checked
                : true; // If not checked, consider valid

            // If `isMinorChipValid` is false, add all chips to missing images (or just one if required)
            const missingMinorChipImages = !isMinorChipValid
                ? [group.minor_chip_damage[0]] // Just add the first chip to indicate missing
                : [];

            return [...missingFiles, ...missingMinorChipImages];
        });

        if (missingImages.length > 0) {
            // Show an error and prevent submission if images are missing
            openSnackbar(
                getSnackbarMessage(null, "Please upload images for all claimed fields and at least one minor chip damage before proceeding."),
                "error",
                4000
            );
            setIsSubmitting(false); // Stop submitting
            return; // Stop execution if validation fails
        }

        // If all images are present, proceed with submission
        showLoader();
        // Navigate to the next page
        navigate(`/mandatory-images/${id}`);
        hideLoader();
    };




    const handleHeaderBackClick = () => {
        window.history.back();
    }


    // const handleAddMinorChipDamage = (area: string) => {
    //     const updatedGroupedFields = groupedFields.map((group) => {
    //         if (group.AREA === area && group.minor_chip_damage.length < 3) {
    //             const newChip = { ...group.minor_chip_damage[group.minor_chip_damage.length - 1] };
    //             newChip.wp_question_id = `${newChip.wp_question_id}_minor_${group.minor_chip_damage.length + 1}`;
    //             newChip.image_uri = null;
    //             return {
    //                 ...group,
    //                 minor_chip_damage: [...group.minor_chip_damage, newChip],
    //             };
    //         }
    //         return group;
    //     });
    //     console.log('updatedGroupedFields', updatedGroupedFields);

    //     setGroupedFields(updatedGroupedFields);
    // };




    const getNextAdditionalImageId = (data: FileDetail[] | []) => {
        // Extract `additional_image_id` values and convert them to numbers
        const ids = data
            .map(item => Number(item.additional_image_id))
            .filter(id => !isNaN(id)); // Filter out invalid (NaN) values

        // Get the max value or -1 if no valid ids are found
        const maxId = ids.length > 0 ? Math.max(...ids) : -1;

        // Return max + 1, or 0 if maxId is -1
        return maxId + 1;
    }

    const handleAddMinorChipDamage = (area: string, minorDamageList: FileDetail[]) => {
        const updatedGroupedFields = groupedFields.map((group) => {
            // if (group.AREA === area && group.minor_chip_damage.length < 3) {
            if (group.AREA === area && group.minor_chip_damage.length < maxMinorCount) {
                // Create a new minor chip damage based on the last item in the list
                const newChip = { ...group.minor_chip_damage[group.minor_chip_damage.length - 1] };
                newChip.wp_question_id = `${newChip.wp_question_id.replace(/_minor_\d+$/, '')}_minor_${group.minor_chip_damage.length + 1}`;
                newChip.image_uri = null; // Reset the image URI for the new chip
                newChip.additional_image_id = getNextAdditionalImageId(minorDamageList).toString();

                return {
                    ...group,
                    minor_chip_damage: [...group.minor_chip_damage, newChip],
                };
            }
            return group;
        });

        setGroupedFields(updatedGroupedFields);

        // Flatten grouped fields to get all files and minor chips and update the API context
        const updatedFields = updatedGroupedFields.flatMap((group) => [
            ...group.files,
            ...group.minor_chip_damage,
        ]);
        // console.log('updatedGroupedFields', updatedGroupedFields);

        // Update the API context with the new flattened list
        updateApiContext(updatedFields);
        // Use a delay to ensure scroll happens after re-render
        setTimeout(() => {
            if (scrollToAddButtonRef.current) {
                scrollToAddButtonRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 100); // 100ms delay to allow re-render
    };



    // const handleMinorChipDamageToggle = async (event: React.ChangeEvent<HTMLInputElement>, groupArea: string) => {
    //     const isChecked = event.target.checked; // Get whether the checkbox is checked or not

    //     const updatedGroupedFields = await Promise.all(
    //         groupedFields.map(async (group) => {
    //             if (group.AREA === groupArea && group.minor_chip_damage) {
    //                 // If unchecked, remove all but the first minor chip damage and clear images
    //                 const updatedMinorChipDamage = isChecked
    //                     ? group.minor_chip_damage // Keep all items if checked
    //                     : [{ ...group.minor_chip_damage[0], image_uri: null }]; // Keep only the first item with image URI cleared if unchecked

    //                 return {
    //                     ...group,
    //                     minor_chip_damage: updatedMinorChipDamage, // Update with only one minor chip if unchecked
    //                     is_minor_chip_checked: isChecked, // Update the checked state for the group
    //                 };
    //             }
    //             return group;
    //         })
    //     );

    //     let removed_elements: FileDetail[] = [];
    //     let group_first_item: FileDetail | null = null;

    //     if (!isChecked) {
    //         // Perform deletion action for all minor chips except the first one
    //         const group = groupedFields.find((g) => g.AREA === groupArea);
    //         if (group && group.minor_chip_damage.length > 0) {
    //             group_first_item = group.minor_chip_damage[0];
    //             // Capture all minor chip elements except the first one to remove their images
    //             removed_elements = group.minor_chip_damage.slice(1); // Remove all but the first item
    //             // removed_elements = group.minor_chip_damage; // Remove all but the first item

    //             if (group.minor_chip_damage.some(e => e.image_uri)) {
    //                 const wp_id = group.minor_chip_damage[0].wp_question_id.replace(/_minor_\d+$/, ''); // Adjust wp_question_id format
    //                 // await api.deleteImage(apiResponse.manual_upload_id, wp_id);
    //             }
    //         }
    //     }

    //     setGroupedFields(updatedGroupedFields);

    //     if (!isChecked) {
    //         // Flatten grouped fields to get all files and minor chips
    //         const updatedFields = updatedGroupedFields.flatMap((group) => [
    //             ...group.files,
    //             ...group.minor_chip_damage,
    //         ]);

    //         // Filter out the removed elements from the updated claimed fields and updated fields
    //         const filteredUpdatedClaimedFields = apiResponse.claimed_fields
    //             .filter((field: { wp_question_id: string; }) => !removed_elements.some((removed) => removed.wp_question_id === field.wp_question_id));

    //         const filteredUpdatedFields = updatedFields
    //             .filter((field) => !removed_elements.some((removed) => removed.wp_question_id === field.wp_question_id));

    //         // Add any new fields from filteredUpdatedFields that are not already in filteredUpdatedClaimedFields
    //         filteredUpdatedFields.forEach((newField) => {
    //             const isFieldExists = filteredUpdatedClaimedFields.some(
    //                 (existingField: FileDetail) => existingField.wp_question_id === newField.wp_question_id
    //             );

    //             // If the field doesn't exist in claimed_fields, add it
    //             if (!isFieldExists) {
    //                 filteredUpdatedClaimedFields.push(newField);
    //             }
    //         });

    //         for (let index = 0; index < filteredUpdatedClaimedFields.length; index++) {
    //             const element: FileDetail = filteredUpdatedClaimedFields[index];
    //             if (group_first_item && element.wp_question_id == group_first_item.wp_question_id) {
    //                 element.image_uri = null;
    //             }
    //         }
    //         // Update the context with the newly constructed claimed_fields
    //         setApiResponse({
    //             ...apiResponse,
    //             claimed_fields: filteredUpdatedClaimedFields,
    //         });
    //     }
    // };




    const handleMinorChipDamageToggle = async (event: React.ChangeEvent<HTMLInputElement>, groupArea: string) => {
        const isChecked = event.target.checked; // Get whether the checkbox is checked or not

        const updatedGroupedFields = await Promise.all(
            groupedFields.map(async (group) => {
                if (group.AREA === groupArea && group.minor_chip_damage) {
                    // If unchecked, remove all but the first minor chip damage and clear images
                    const updatedMinorChipDamage = isChecked
                        ? group.minor_chip_damage // Keep all items if checked
                        : [{ ...group.minor_chip_damage[0], image_uri: null }]; // Keep only the first item with image URI cleared if unchecked

                    return {
                        ...group,
                        minor_chip_damage: updatedMinorChipDamage, // Update with only one minor chip if unchecked
                        is_minor_chip_checked: isChecked, // Update the checked state for the group
                    };
                }
                return group;
            })
        );

        let removed_elements: FileDetail[] = [];
        let group_first_item: FileDetail | null = null;

        if (!isChecked) {
            try {
                showLoader();
                // Perform deletion action for all minor chips except the first one
                const group = groupedFields.find((g) => g.AREA === groupArea);
                if (group && group.minor_chip_damage.length > 0) {
                    group_first_item = group.minor_chip_damage[0];
                    // Capture all minor chip elements except the first one to remove their images
                    removed_elements = group.minor_chip_damage.slice(1); // Remove all but the first item

                    if (group.minor_chip_damage.some(e => e.image_uri)) {
                        const wp_id = group.minor_chip_damage[0].wp_question_id.replace(/_minor_\d+$/, ''); // Adjust wp_question_id format
                        await api.deleteImage(apiResponse.manual_upload_id, wp_id);
                    }
                }

                // Update the UI state only if deletion succeeds
                setGroupedFields(updatedGroupedFields);

                if (!isChecked) {
                    // Flatten grouped fields to get all files and minor chips
                    const updatedFields = updatedGroupedFields.flatMap((group) => [
                        ...group.files,
                        ...group.minor_chip_damage,
                    ]);

                    // Filter out the removed elements from the updated claimed fields and updated fields
                    const filteredUpdatedClaimedFields = apiResponse.claimed_fields
                        .filter((field: { wp_question_id: string; }) => !removed_elements.some((removed) => removed.wp_question_id === field.wp_question_id));

                    const filteredUpdatedFields = updatedFields
                        .filter((field) => !removed_elements.some((removed) => removed.wp_question_id === field.wp_question_id));

                    // Add any new fields from filteredUpdatedFields that are not already in filteredUpdatedClaimedFields
                    filteredUpdatedFields.forEach((newField) => {
                        const isFieldExists = filteredUpdatedClaimedFields.some(
                            (existingField: FileDetail) => existingField.wp_question_id === newField.wp_question_id
                        );

                        // If the field doesn't exist in claimed_fields, add it
                        if (!isFieldExists) {
                            filteredUpdatedClaimedFields.push(newField);
                        }
                    });

                    // Ensure that the first minor chip has its image_uri set to null
                    for (let index = 0; index < filteredUpdatedClaimedFields.length; index++) {
                        const element: FileDetail = filteredUpdatedClaimedFields[index];
                        if (group_first_item && element.wp_question_id === group_first_item.wp_question_id) {
                            element.image_uri = null;
                        }
                    }

                    // Update the context with the newly constructed claimed_fields
                    setApiResponse({
                        ...apiResponse,
                        claimed_fields: filteredUpdatedClaimedFields,
                    });
                }
            } catch (error) {
                console.error("Failed to delete images from API:", error);
                openSnackbar("Failed to delete images. Please try again.", "error", 4000);
            } finally {
                hideLoader();
            }
        } else {
            // If checked, directly update the UI state without any API call
            setGroupedFields(updatedGroupedFields);
        }
    };





    const handleDeleteChipDamage = (area: string, chipToDelete: FileDetail) => {
        const updatedGroupedFields = groupedFields.map((group) => {
            if (group.AREA === area && group.minor_chip_damage) {
                // Filter out the chip to delete
                const updatedMinorChipDamage = group.minor_chip_damage.filter((chip) => chip.wp_question_id !== chipToDelete.wp_question_id);

                // Delete the image if it exists for the chip being removed
                if (chipToDelete.image_uri) {
                    handleDeleteImage(chipToDelete.wp_question_id, true);
                }

                return {
                    ...group,
                    minor_chip_damage: updatedMinorChipDamage,
                };
            }
            return group;
        });

        // Update the grouped fields state
        setGroupedFields(updatedGroupedFields);

        // Update the API context
        const updatedFields = updatedGroupedFields.flatMap((group) => [
            ...group.files,
            ...(group.minor_chip_damage ? group.minor_chip_damage : [])
        ]);
        updateApiContext(updatedFields, chipToDelete.wp_question_id);
    };





    return (
        <MainContainer
            headerText="Damage Capture"
            isBack={true}
            onBack={handleHeaderBackClick}
            showFooter={true}
            footerButtonLabel={isSubmitting ? 'Please wait...' : 'Next'}
            onFooterButtonClick={handleFooterButtonClick}>
            <section className={styles.damage_capture_body_outer}>
                {/* {error && <p className="custom_error">{error}</p>} */}
                {groupedFields.length > 0 ? (
                    groupedFields.map((group, group_index) => (
                        <div key={group.AREA} className={styles.damage_capture_body_inner}>
                            <p className={`m-0 ${styles.damage_capture_group_header_text}`}>{group.AREA.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase())}</p>
                            <div className={styles.damage_group_body_outer}>
                                {group.files.map((file, file_index) => (
                                    <div className={styles.image_uploader_wrapper} key={`${file.wp_question_id}_${group.AREA}_${file_index}`}>
                                        <ImageUploader
                                            key={`${file.wp_question_id}_${group.AREA}_${file_index}`}
                                            wp_question_id={file.wp_question_id}
                                            label={file.template_body_value || ""}
                                            image_uri={file.image_uri}
                                            onDeleteImage={() => handleDeleteImage(file.wp_question_id)}
                                            onUploadImage={(wp_question_id: string, file: File) => handleUploadImage(wp_question_id, file)}
                                            onBase64Preview={handleBase64Preview}
                                            assessment_file_id={file.assessment_file_id}
                                            tags={file.tags}
                                            is_claimed={file.is_claimed}
                                            sequence_id={file.sequence_id}
                                            overlay_url={file.overlay_url || ""}
                                        />
                                    </div>
                                ))}





                                {/* Minor chip damage checkbox */}
                                {/* {group.minor_chip_damage && (
                                    <div className={styles.minor_chip_damage_section}>
                                        <CustomCheckbox
                                            checked={!!group.is_minor_chip_checked}  // Ensure this is a boolean, using !! to coerce any undefined values
                                            onChange={(event) => handleMinorChipDamageToggle(event, group.AREA)}  // Correctly pass the event handler
                                            label="Add Minor Chip Damage"  // The label can be a string or JSX element
                                            id={`${group.minor_chip_damage.wp_question_id}_${group.AREA}_minor_${group_index}`}
                                        />




                                        {group.is_minor_chip_checked && (
                                            <div className={styles.minor_chip_damage_section_inner}>
                                                <p className={styles.minor_chip_damage_section_area_header}>
                                                    {group.AREA?.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase())} Minor Chip Damage
                                                </p>
                                                <ImageUploader
                                                    key={`${group.minor_chip_damage.wp_question_id}_${group.AREA}_minor_${group_index}`}
                                                    wp_question_id={group.minor_chip_damage.wp_question_id}
                                                    label={group?.minor_chip_damage.template_body_value || ""}
                                                    image_uri={group.minor_chip_damage.image_uri}
                                                    onDeleteImage={() => handleDeleteImage(group.minor_chip_damage.wp_question_id, true)}
                                                    onUploadImage={(wp_question_id: string, file: File) => handleUploadImage(wp_question_id, file, true)}
                                                    onBase64Preview={handleBase64Preview}
                                                    assessment_file_id={group.minor_chip_damage.assessment_file_id}
                                                    tags={group.minor_chip_damage.tags}
                                                    is_claimed={group.minor_chip_damage.is_claimed}
                                                    sequence_id={group.minor_chip_damage.sequence_id}
                                                    isMinorChip={true}
                                                    overlay_url={group.minor_chip_damage.overlay_url || ""}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )} */}




                                {/* {group.minor_chip_damage && group.minor_chip_damage.length > 0 && (
                                    <div className={styles.minor_chip_damage_section}>
                                        {group.minor_chip_damage.map((chip, index) => (
                                            <div key={`${chip.wp_question_id}_${group.AREA}_minor_${index}`}>
                                                <CustomCheckbox
                                                    checked={!!group.is_minor_chip_checked}  // Ensure this is a boolean, using !! to coerce any undefined values
                                                    onChange={(event) => handleMinorChipDamageToggle(event, group.AREA)}  // Correctly pass the event handler
                                                    label="Add Minor Chip Damage"  // The label can be a string or JSX element
                                                    id={`${chip.wp_question_id}_${group.AREA}_minor_${index}`}
                                                />

                                                {group.is_minor_chip_checked && (
                                                    <div className={styles.minor_chip_damage_section_inner}>
                                                        <p className={styles.minor_chip_damage_section_area_header}>
                                                            {group.AREA?.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase())} Minor Chip Damage
                                                        </p>
                                                        <ImageUploader
                                                            key={`${chip.wp_question_id}_${group.AREA}_minor_${index}`}
                                                            wp_question_id={chip.wp_question_id}
                                                            label={chip.template_body_value || ""}
                                                            image_uri={chip.image_uri}
                                                            onDeleteImage={() => handleDeleteImage(chip.wp_question_id, true)}
                                                            onUploadImage={(wp_question_id: string, file: File) => handleUploadImage(wp_question_id, file, true)}
                                                            onBase64Preview={handleBase64Preview}
                                                            assessment_file_id={chip.assessment_file_id}
                                                            tags={chip.tags}
                                                            is_claimed={chip.is_claimed}
                                                            sequence_id={chip.sequence_id}
                                                            isMinorChip={true}
                                                            overlay_url={chip.overlay_url || ""}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )} */}







                                {group.minor_chip_damage && group.minor_chip_damage.length > 0 && (
                                    <div className={styles.minor_chip_damage_section}>
                                        {/* Render the checkbox once */}
                                        <CustomCheckbox
                                            checked={!!group.is_minor_chip_checked} // Ensure this is a boolean
                                            onChange={(event) => handleMinorChipDamageToggle(event, group.AREA)}
                                            label="Add Minor Chip Damage"
                                            id={`${group.AREA}_minor_checkbox`}
                                        />

                                        {/* Conditionally show the minor chip damages and Add button based on is_minor_chip_checked */}
                                        {group.is_minor_chip_checked && (
                                            <>
                                                {/* Render each minor chip damage item */}
                                                {group.minor_chip_damage.map((chip, index) => (
                                                    <React.Fragment key={`${chip.wp_question_id}_${group.AREA}_minor_${index}`}>
                                                        <div key={`${chip.wp_question_id}_${group.AREA}_minor_${index}`} className={styles.minor_chip_damage_section_inner}>
                                                            <p className={styles.minor_chip_damage_section_area_header}>
                                                                {group.AREA?.replace(/_/g, ' ').toLowerCase().replace(/\b\w/g, char => char.toUpperCase())} Minor Chip Damage
                                                            </p>
                                                            <ImageUploader
                                                                key={`${chip.wp_question_id}_${group.AREA}_minor_${index}`}
                                                                wp_question_id={chip.wp_question_id}
                                                                label={chip.template_body_value || ""}
                                                                image_uri={chip.image_uri}
                                                                onDeleteImage={() => handleDeleteImage(chip.wp_question_id, true)}
                                                                onUploadImage={(wp_question_id: string, file: File) => handleUploadImage(wp_question_id, file, true)}
                                                                onBase64Preview={handleBase64Preview}
                                                                assessment_file_id={chip.assessment_file_id}
                                                                tags={chip.tags}
                                                                is_claimed={chip.is_claimed}
                                                                sequence_id={chip.sequence_id}
                                                                isMinorChip={true}
                                                                overlay_url={chip.overlay_url || ""}
                                                            />
                                                            {group.minor_chip_damage.length > 1 && !group.minor_chip_damage[index].image_uri && (
                                                                <div className={styles.delete_minor_outer} key={`${chip.wp_question_id}_${group.AREA}_minor_remove_button_${index}`}>
                                                                    <RemoveIcon color='warning' className={styles.minor_remove_icon} onClick={() => handleDeleteChipDamage(group.AREA, chip)} />
                                                                </div>
                                                            )
                                                            }
                                                        </div>




                                                    </React.Fragment>
                                                ))}

                                                {/* Add button - only show if there are fewer than 3 minor chip damages */}
                                                {group.minor_chip_damage.length < maxMinorCount && (
                                                    <div className={styles.add_more_minor_outer} ref={scrollToAddButtonRef}>
                                                        <CustomButton
                                                            variant='outlined'
                                                            color='success'
                                                            label='Add More Minor Chip Damage'
                                                            startIcon={AddIcon}
                                                            onClick={() => handleAddMinorChipDamage(group.AREA, group.minor_chip_damage)} disabled={group.minor_chip_damage[group.minor_chip_damage?.length - 1].image_uri ? false : true}
                                                        />
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}


                            </div>
                        </div>
                    ))
                ) : (
                    <p>No claimed fields available.</p>
                )}
            </section>
        </MainContainer>
    );
};

export default DamageCapture;


import React from 'react';
import CustomButton from '../../../components/CustomButton';
import InfoIcon from '@mui/icons-material/Info';
import styles from './Footer.module.scss'; // Import the CSS module

interface FooterProps {
    footerButtonLabel: string;
    onFooterButtonClick: () => void;
    buttonIcon?: JSX.Element; // Optional prop for button icon
    footerInfoText?: string;
}

const Footer: React.FC<FooterProps> = ({
    footerButtonLabel,
    onFooterButtonClick,
    buttonIcon,
    footerInfoText
}) => {
    return (
        <footer className={styles.footer_body_outer}>
            <div className={styles.footer_body_inner}>
                <div className={styles.footer_info_body_outer}>
                    {footerInfoText && (
                        <div className={styles.footer_info_body_inner}>
                            <div className={styles.info_icon_part}>
                                <InfoIcon color='primary' className={styles.info_icon} />
                            </div>
                            <div className={styles.info_text_part}>
                                <p className="m-0">{footerInfoText}</p>
                            </div>
                        </div>
                    )}
                </div>
                <div className={styles.footer_bottom_button}>
                    <CustomButton
                        label={footerButtonLabel}
                        onClick={onFooterButtonClick}
                    />
                </div>
            </div>
        </footer>
    );
};

export default Footer;

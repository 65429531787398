import React, { useEffect } from 'react';
import MainContainer from '../MainContainer/MainContainer';
import styles from './Error.module.scss'; // Import the CSS Module
import { SessionStorageService } from '../../utilities/SessionStorage';
import { useLocation, useNavigate } from 'react-router-dom';
import { STATUS } from '../../constants/constants';

const ErrorPage: React.FC = () => {

  const location = useLocation();

  // Parse the query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');

  useEffect(() => {
    // Clear sessionStorage when the error page is loaded
    SessionStorageService.clear(); // This clears all sessionStorage items
    // Alternatively, you can use sessionStorage.removeItem(storageKey.token) to remove just the token
  }, []);

  return (
    <MainContainer
      headerText={mode === STATUS.EXPIRED ? 'Link Expired' : 'Error'}
    >
      <section className={styles.status_container_outer}>
        <div className={styles.status_container_inner}>
          <div className={styles.img_part}>
            <img
              src="/images/status/expired.svg"
              alt=""
            />
          </div>
          <div className={styles.text_part}>
            <div className={styles.main_text}>
              <p className="m-0">
                {mode === STATUS.EXPIRED
                  ? "The link you're trying to access has expired."
                  : 'Something went wrong, please contact helpdesk!'}

              </p>
            </div>
          </div>
        </div>
      </section>

    </MainContainer>
  );
};

export default ErrorPage;

import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './Header.module.scss';  // Import the CSS module

interface HeaderProps {
  headerText: string;
  isBack?: boolean;
  onBack?: () => void;
}

const Header: React.FC<HeaderProps> = ({ headerText, isBack = false, onBack }) => {
  const handleBackClick = () => {
    if (onBack) {
      onBack();
    }
  };

  return (
    <header className={styles.header_body}>
      <div className={styles.header_top_body}>
        {isBack && (
          <div className={styles.arrow_part}>
            <div onClick={handleBackClick} className={styles.back_button}>
              <ArrowBackIcon className={styles.arrow_back_icon} />
            </div>
          </div>
        )}
        <div className={styles.text_part}>
          <p className={`${styles.header_text} m-0`}>{headerText}</p>
        </div>
      </div>
      <div className={styles.header_bottom_body}>
      </div>
    </header>
  );
};

export default Header;

import React from 'react';
import { Navigate } from 'react-router-dom';
import { SessionStorageService } from '../utilities/SessionStorage';
import { storageKey } from '../constants/constants';

const isLoggedIn = (): boolean => {
  const token = SessionStorageService.getItem(storageKey.token);
  return !!token; // Ensure token exists
};

interface AuthGuardProps {
  children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({ children }) => {
  const loggedIn = isLoggedIn();

  if (!loggedIn) {
    return <Navigate to="/error" replace />;
  }

  return <>{children}</>;
};

export default AuthGuard;

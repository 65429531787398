import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { useNavigate, useParams } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import ImageUploader from '../../Shared/Components/ImageUploader/ImageUploader';
import styles from './MandatoryImages.module.scss'; // Import the CSS Module
import { useLoader } from '../../context/LoaderContext';
import { useSnackbar } from '../../context/SnackbarContext';
import { getCallbackURL, getOTP, getSnackbarMessage } from '../../utilities/GenericService';
import { VIN_PATTERN } from '../../constants/patterns';
import { FileDetail as BaseFileDetail } from '../../constants/dataTypes';
import api from '../../api/api';
import { STATUS, TAG_TYPE } from '../../constants/constants';
import { SessionStorageService } from '../../utilities/SessionStorage';

interface FileDetail extends BaseFileDetail {
  vinNumber: string | "" | undefined | null;  // Adding an optional field
  isVin?: boolean;
}

interface GroupedFields {
  AREA: string;
  files: FileDetail[];
}

const MandatoryImages: React.FC = () => {
  const { token, apiResponse, setApiResponse } = useContext(ApiContext);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Get the id from the URL
  const [mandatoryFields, setMandatoryFields] = useState<FileDetail[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { showLoader, hideLoader } = useLoader();
  const { openSnackbar } = useSnackbar();
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);
  const [groupedFields, setGroupedFields] = useState<GroupedFields[]>([]);
  const isInitialMount = useRef(true); // This flag will help ensure logic runs only once



  useEffect(() => {
    showLoader();

    // Ensure this logic runs only on initial mount
    if (isInitialMount.current) {
      if (!id) {
        navigate('/error', { replace: true });
        hideLoader();
        return;
      }

      if (!token || !apiResponse) {
        hideLoader();
        navigate(`/${getOTP()}/?_id=${id}`, { replace: true });
        return;
      }

      // Set only the mandatory fields for VIN handling on initial load
      let mandatory_fields: FileDetail[] = apiResponse?.mandatory_fields || [];
      if (!mandatory_fields?.length) {
        mandatory_fields = apiResponse.file_details.filter((file: FileDetail) => file.is_claimed === false);
      }
      mandatory_fields?.forEach((e) => {
        if (e.tags[0].tag_type === TAG_TYPE.VEHICLE_IDENTIFIER) {
          e.isVin = true;
          e.vinNumber = e.tags[0].vin_number || "";
          updateVinInFileDetails(e.wp_question_id, e.tags[0].vin_number || "");
        }
      });
      // Sort the fields such that those with isVin: true come first
      // mandatory_fields.sort((a, b) => (b?.isVin ? 1 : -1));

      // mandatory_fields.sort((a, b) => {
      //   // First, prioritize isVin being true
      //   if (a.isVin && !b.isVin) return -1;
      //   if (!a.isVin && b.isVin) return 1;

      //   // If both have isVin false or true, sort by label
      //   const labelA = a.label?.toLowerCase() || "";
      //   const labelB = b.label?.toLowerCase() || "";

      //   // Prioritize "vin number" first
      //   if (labelA.includes("vin number") && !labelB.includes("vin number")) return -1;
      //   if (!labelA.includes("vin number") && labelB.includes("vin number")) return 1;

      //   // Then prioritize "glass brand"
      //   if (labelA.includes("glass brand") && !labelB.includes("glass brand")) return -1;
      //   if (!labelA.includes("glass brand") && labelB.includes("glass brand")) return 1;

      //   // Keep the original order if none of the above conditions match
      //   return 0;
      // });


      mandatory_fields.sort((a, b) => {
        // First, prioritize isVin being true
        if (a.isVin && !b.isVin) return -1;
        if (!a.isVin && b.isVin) return 1;

        // If both are either isVin true or both false, sort by label with a specific priority
        const getLabelPriority = (label: string) => {
          const lowerLabel = label?.toLowerCase() || "";

          if (lowerLabel.includes("vin number")) return 1; // Priority for "vin number"
          if (lowerLabel.includes("glass brand")) return 2; // Priority for "glass brand"
          return 3; // All other labels will have the lowest priority
        };

        const labelPriorityA = getLabelPriority(a.label || "");
        const labelPriorityB = getLabelPriority(b.label || "");

        // Sort based on the priority
        return labelPriorityA - labelPriorityB;
      });

      setMandatoryFields(mandatory_fields);

      // After initial mount, set the flag to false
      isInitialMount.current = false;
    }




    let all_claimed_fields: FileDetail[] = apiResponse.claimed_fields || [];
    if (!all_claimed_fields?.length) {
      all_claimed_fields = apiResponse.file_details.filter((file: FileDetail) => file.is_claimed === true);
    }

    // Grouping logic to be executed on every render or as required
    const selectedAreas = apiResponse?.all_claim_types
      .filter((claimType: { AREA: string; isChecked: boolean }) => claimType.isChecked)
      .map((claimType: { AREA: string }) => claimType.AREA);

    const selectedFields: FileDetail[] = all_claimed_fields?.filter((file: FileDetail) => {
      if (!file || !file.tags) return false;
      const subAreaTag = file.tags.find((tag) => tag.tag_type === TAG_TYPE.SUB_AREA);
      return subAreaTag && selectedAreas.includes(subAreaTag.value);
    });

    const groupedByArea = selectedAreas?.map((area: any) => {
      const areaFiles: FileDetail[] = selectedFields.filter((file: FileDetail) =>
        file?.tags?.find((tag) => tag.tag_type === TAG_TYPE.SUB_AREA && tag.value === area)
      );

      const files: FileDetail[] = [];
      let minorChipDamage: FileDetail | null = null;

      // areaFiles.forEach((file: FileDetail) => {
      //   // Check if this file matches an already added file based on template_body_value
      //   const existingFileIndex = files.findIndex(
      //     (f: FileDetail) => f.template_body_value === file.template_body_value
      //   );

      //   // If no minor chip damage has been assigned yet and it's a duplicate, assign it as the minor chip damage
      //   if (existingFileIndex !== -1 && !minorChipDamage) {
      //     // If a match is found and no minor chip has been flagged, this file becomes the minor_chip_damage
      //     minorChipDamage = { ...file }; // Add is_minor flag to this file
      //   } else {
      //     // Either it's the first occurrence of the file, or it's not a duplicate
      //     files.push({ ...file }); // Ensure regular files also have is_minor: false
      //   }
      // });


      areaFiles.forEach((file: FileDetail) => {
        // If the file is marked as minor_chip_damage by the backend
        if (file.is_minor_damage) {
          // Assign it as the minor chip damage
          minorChipDamage = { ...file };
        } else {
          // Otherwise, it's a regular file, so add it to the files array
          files.push({ ...file });
        }
      });


      return {
        AREA: area,
        files: files,
      };
    });

    setGroupedFields(groupedByArea);

    hideLoader();
  }, [token, apiResponse, navigate]);

  // Handle base64 preview (when an image is chosen but not yet uploaded)
  const handleBase64Preview = (wp_question_id: string, base64String: string | null) => {
    const updatedMandatoryFields = mandatoryFields.map((fileDetail) =>
      fileDetail.wp_question_id === wp_question_id ? { ...fileDetail, image_uri: base64String } : fileDetail
    );
    setMandatoryFields(updatedMandatoryFields); // Update local state
  };

  // Helper: Update the API response in context with the updated mandatory fields
  const updateApiContext = (updatedMandatoryFields: FileDetail[]) => {
    const updatedResponse = {
      ...apiResponse,
      mandatory_fields: updatedMandatoryFields, // Only update mandatory_fields
    };
    setApiResponse(updatedResponse); // Update the context with the new data
  };


  const handleUploadImage = async (wp_question_id: string, file: File, vinNumber?: string) => {
    showLoader();
    try {
      const formData = new FormData();
      formData.append('file', file, file.name); // Append the file

      const updatedData: FileDetail = JSON.parse(JSON.stringify(mandatoryFields.find((e: FileDetail) => e.wp_question_id === wp_question_id)));
      if (updatedData?.isVin) {
        updatedData.tags[0].vin_number = vinNumber || null;
        delete updatedData?.vinNumber;
        delete updatedData?.isVin;
      }

      // Append the JSON payload as a string
      formData.append('request', JSON.stringify(updatedData));

      // Make the API call with the correct content-type
      await api.uploadImage(apiResponse.manual_upload_id, formData);

      // Convert the file to base64
      const base64String = await convertToBase64(file);

      // Update mandatory fields locally with base64 image and vinNumber conditionally
      const updatedMandatoryFields = mandatoryFields.map((fileDetail) =>
        fileDetail.wp_question_id === wp_question_id
          ? {
            ...fileDetail,
            image_uri: base64String ?? null,
            // Conditionally update vinNumber only if the label contains "vin"
            vinNumber: fileDetail?.isVin ? vinNumber : null
          }
          : fileDetail
      );

      // Update the local mandatory fields and API context
      setMandatoryFields(updatedMandatoryFields);
      updateApiContext(updatedMandatoryFields); // Update context
    } catch (err) {

      // Handle the error
      openSnackbar(getSnackbarMessage(err), "error", 4000);
      // Clear the base64 preview if there's an error
      handleBase64Preview(wp_question_id, null);
      // Set the vinNumber to blank in case of error
      const updatedMandatoryFields = mandatoryFields.map((fileDetail) =>
        fileDetail.wp_question_id === wp_question_id && fileDetail?.isVin
          ? {
            ...fileDetail,
            vinNumber: null  // Set VIN to blank
          }
          : fileDetail
      );
      // Ensure a fresh copy of the state is set to trigger re-renders
      setMandatoryFields([...updatedMandatoryFields]);
      updateApiContext([...updatedMandatoryFields]); // Update context
    } finally {
      hideLoader();
    }
  };


  // Handle image delete
  const handleDeleteImage = async (wp_question_id: string) => {
    showLoader();
    const previousImageUri = mandatoryFields.find((fileDetail) => fileDetail.wp_question_id === wp_question_id)?.image_uri ?? null;

    try {
      // Simulate API call to delete image
      // await axios.get('https://jsonplaceholder.typicode.com/posts');
      await api.deleteImage(apiResponse.manual_upload_id, wp_question_id);

      const updatedMandatoryFields = mandatoryFields.map((fileDetail) =>
        fileDetail.wp_question_id === wp_question_id
          ? {
            ...fileDetail,
            image_uri: null,
            vinNumber: null  // Clear VIN value if it's a VIN-related image
          }
          : fileDetail
      );

      setMandatoryFields(updatedMandatoryFields);
      updateApiContext(updatedMandatoryFields);
      hideLoader();
    } catch (err) {
      console.error('Error deleting image:', err);

      const updatedMandatoryFields = mandatoryFields.map((fileDetail) =>
        fileDetail.wp_question_id === wp_question_id
          ? {
            ...fileDetail,
            image_uri: previousImageUri,
            vinNumber: fileDetail?.isVin ? fileDetail.vinNumber : fileDetail.vinNumber  // Retain previous VIN value in case of error
          }
          : fileDetail
      );

      setMandatoryFields(updatedMandatoryFields);
      updateApiContext(updatedMandatoryFields);
      openSnackbar(getSnackbarMessage(err), 'error', 4000);
      hideLoader();
    }
  };

  // Handle VIN input changes from the child with debounce and validation
  // const handleVinInputChange = async (wp_question_id: string, vin: string) => {
  //   // Update the VIN in real-time, regardless of validation status
  //   updateVinInFileDetails(wp_question_id, vin);

  //   // Clear previous timeout if one exists
  //   if (debounceTimer.current) {
  //     clearTimeout(debounceTimer.current);
  //   }

  //   // Set a new debounce timeout for validation and API call
  //   debounceTimer.current = setTimeout(() => {
  //     // Check if the VIN is valid
  //     if (VIN_PATTERN.test(vin)) {
  //       // Re-update the VIN after debounce (only for valid VINs)
  //       updateVinInFileDetails(wp_question_id, vin);
  //       // Trigger the API call for valid VINs
  //       makeVinApiCall(wp_question_id, vin);
  //     }
  //   }, 500); // 500ms debounce
  // };

  // Update VIN in mandatoryFields for the specific field
  const updateVinInFileDetails = (wp_question_id: string, vin: string) => {
    const updatedMandatoryFields = mandatoryFields.map((fileDetail) =>
      fileDetail.wp_question_id === wp_question_id
        ? {
          ...fileDetail,
          vinNumber: vin, // Update the vinNumber for the corresponding field
        }
        : fileDetail
    );

    setMandatoryFields(updatedMandatoryFields);
    updateApiContext(updatedMandatoryFields); // Update the context with the new data
  };

  // Function to make API call for VIN validation or processing
  // const makeVinApiCall = async (wp_question_id: string, vin: string) => {
  //   showLoader();
  //   try {
  //     const payload = {
  //       "vinNumber": vin,
  //       "link_id": id,
  //       "save_as_draft": true
  //     }
  //     await api.patchManualUpload(apiResponse.manual_upload_id, payload);
  //     updateVinInFileDetails(wp_question_id, vin);
  //   } catch (err) {
  //     updateVinInFileDetails(wp_question_id, "");
  //     console.error('Error updating VIN:', err);
  //   } finally {
  //     hideLoader();
  //   }
  // };

  // Convert file to base64 string
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleFooterButtonClick = () => {
    if (isSubmitting) {
      return; // Prevent multiple submissions
    }

    // Mark as submitting to prevent re-clicks
    setIsSubmitting(true);

    // Validate mandatory fields
    const isFormValid = validateForm();
    if (!isFormValid) {
      setIsSubmitting(false); // Reset submitting state after validation failure
      return;
    }

    // If form is valid, proceed with submission
    proceedWithSubmission();
  };

  const validateForm = () => {
    const missingClaimedImages = groupedFields.flatMap((group) => {
      // Check for files without image
      const missingFiles = group.files.filter((file) => !file.image_uri);
      return [...missingFiles];
    });

    if (missingClaimedImages.length > 0) {
      openSnackbar(getSnackbarMessage(null, "Please upload images for all claimed fields and selected minor chip damages before proceeding."), "error", 4000);
      setIsSubmitting(false); // Stop submitting
      return; // Stop execution if validation fails
    }

    // Validate if all mandatory fields have images
    const missingImages = mandatoryFields.filter((file) => !file.image_uri);
    if (missingImages.length > 0) {
      openSnackbar("Please upload images for all mandatory fields.", "error", 4000);
      return false; // Validation failed
    }

    // Validate if any field with "vin" label is missing vinNumber or has invalid VIN format
    // const invalidVinFields = mandatoryFields.filter((file) => {
    //   const vin = file.vinNumber?.trim(); // Trim any extra whitespace
    //   const isVinInvalid = file?.isVin && (!vin || !VIN_PATTERN.test(vin));
    //   return isVinInvalid;
    // });

    // if (invalidVinFields.length > 0) {
    //   openSnackbar("Please ensure all VIN fields have valid VIN values.", "error", 4000);
    //   return false; // Validation failed
    // }
    return true; // Validation passed
  };

  const proceedWithSubmission = async () => {
    try {
      showLoader();
      const payload = {
        "link_id": id,
        "preferred_location": apiResponse.browser_location,
        "save_as_draft": false
      }
      await api.patchManualUpload(apiResponse.manual_upload_id, payload);
      // Redirect after successful submission
      const callbackURL = getCallbackURL();
      if (callbackURL) {
        // Replace the current history state to prevent back navigation
        window.history.replaceState(null, "", window.location.href);
        // Use window.location.replace to prevent adding a new entry to history
        window.location.replace(callbackURL);
      } else {
        // Navigate to the status page (replace: true will ensure the previous page is not kept in history)
        navigate(`/status/${id}?mode=${STATUS.SUBMITTED}`, { replace: true });
      }
    } catch (error) {
      openSnackbar(getSnackbarMessage(error), "error", 4000);
    } finally {
      setIsSubmitting(false); // Reset submitting state
      hideLoader();
    }
  };


  const handleHeaderBackClick = () => {
    window.history.back();
  }

  return (
    <MainContainer
      headerText="Mandatory Images"
      isBack={true}
      onBack={handleHeaderBackClick}
      showFooter={true}
      footerButtonLabel={isSubmitting ? 'Please wait...' : 'Next'}
      onFooterButtonClick={handleFooterButtonClick}
    >
      <section className={styles.mandatory_body_outer}>
        {/* {error && <p className="custom_error">{error}</p>} */}
        {mandatoryFields.length > 0 ? (
          <div className={styles.mandatory_body_inner}>
            {mandatoryFields.map((file) => (
              <div className={styles.image_uploader_wrapper} key={file.wp_question_id}>
                <ImageUploader
                  key={file.wp_question_id}
                  wp_question_id={file.wp_question_id}
                  label={file.label || ""}
                  image_uri={file.image_uri} // Pass the updated image URI
                  onDeleteImage={handleDeleteImage}
                  onUploadImage={handleUploadImage}
                  onBase64Preview={handleBase64Preview} // Pass base64 preview handler
                  assessment_file_id={null} tags={[]} is_claimed={false} sequence_id={null}
                  vinNumber={file?.vinNumber}
                  // onVinChange={handleVinInputChange}
                  google_vision_api_key={apiResponse.google_vision_api_key}
                  overlay_url={file.overlay_url || ""}
                  isVin={file?.isVin}
                />
              </div>
            ))}
          </div>
        ) : (
          <p>No mandatory files available.</p>
        )}
      </section>
    </MainContainer>
  );
};

export default MandatoryImages;

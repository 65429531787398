import React from 'react';
import { Button } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material'; // This is used for the icon component type

interface CustomButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success';
  startIcon?: SvgIconComponent; // Add startIcon prop for an optional icon on the button
  endIcon?: SvgIconComponent; // Add endIcon prop for an optional end icon
}

const CustomButton: React.FC<CustomButtonProps> = ({ label, onClick, disabled = false, variant = 'contained', color = 'primary', startIcon: StartIcon, endIcon: EndIcon }) => {
  return (
    <Button
      variant={variant}
      color={color}
      disabled={disabled}
      onClick={onClick}
      fullWidth
      startIcon={StartIcon ? <StartIcon /> : null} // Conditionally render startIcon
      endIcon={EndIcon ? <EndIcon /> : null} // Conditionally render endIcon
    >
      {label}
    </Button>
  );
};

export default CustomButton;

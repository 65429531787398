export const endpoint = {
  verifyToken: (id: string): string => {
    return `${process.env.REACT_APP_CARDATA_BASE_SERVICE_URL}/dynamic-link/${id}`;
  },
  refreshTokenAccess: `${process.env.REACT_APP_BASE_SERVER_URL}/api/refresh`,
  getDynamicLinkDetailsDetails: (id: string): string => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/manual-upload/render-form/${id}`;
  },
  initiateManualUpload: `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/manual-upload/initiate`,
  patchManualUpload: (manual_upload_id: string): string => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/manual-upload/${manual_upload_id}/submit-data`;
  },
  uploadImage: (manual_upload_id: string): string => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/manual-upload/${manual_upload_id}/upload-file`;
  },
  deleteImage: (manual_upload_id: string, question_id: string): string => {
    return `${process.env.REACT_APP_CORE_BASE_SERVER_URL}/manual-upload/${manual_upload_id}/file/${question_id}`;
  },
};

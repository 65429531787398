// import axios from 'axios';
// import { storageKey } from '../constants/constants';
// import { SessionStorageService } from '../utilities/SessionStorage';

// const instance = axios.create();

// instance.interceptors.request.use(
//   (config) => {
//     const token: string | null = SessionStorageService.getItem(storageKey.token);
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export default instance;




import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { storageKey } from '../constants/constants';
import { SessionStorageService } from '../utilities/SessionStorage';
import api from '../api/api';
import { nagivateAfterExpiry } from '../utilities/GenericService';

// Define an extended configuration type that includes `_retry`
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

const instance = axios.create();

// Function to refresh the token
const refreshToken = async () => {
  const refreshToken = SessionStorageService.getItem(storageKey.refreshToken); // Assuming you store refresh tokens
  try {
    const payload = {
      refresh_token: refreshToken,
      user_id: SessionStorageService.getItem(storageKey.userId),
    };
    // console.log(sessionStorage.getItem(storageKey.userId));

    SessionStorageService.removeItem(storageKey.token);

    // Make sure the refresh token call does NOT include the Authorization header
    const response = await api.refreshTokenAccess(payload);

    // const response = await axios.post('/auth/refresh', payload, {
    //   headers: { 'Content-Type': 'application/json' }, // No Authorization header
    // });
    const { access_token, refresh_token } = response.data;
    // const newToken = response.data.token;
    SessionStorageService.setItem(storageKey.token, access_token);
    SessionStorageService.removeItem(storageKey.refreshToken);
    SessionStorageService.setItem(storageKey.refreshToken, refresh_token);
    return access_token;
  } catch (error) {
    console.error('Refresh token failed:', error);
    nagivateAfterExpiry();
    throw error;
  }
};

// Request interceptor to add token to headers
instance.interceptors.request.use(
  (config: CustomAxiosRequestConfig) => {
    const token: string | null = SessionStorageService.getItem(storageKey.token);
    if (token) {
      config.headers = config.headers || {};  // Ensure headers exist
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors and refresh the token if needed
instance.interceptors.response.use(
  (response) => {
    return response; // Forward the response if it's successful
  },
  async (error: AxiosError) => {
    const originalRequest = error.config as CustomAxiosRequestConfig;

    // Check if it's a 406 error and the token refresh hasn't been tried yet
    if (error.response?.status === 406 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried to avoid loops

      try {
        // Call the refresh token API to get a new token
        const newToken = await refreshToken();

        // Update the authorization header with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;

        // Retry the original request with the new token
        return instance(originalRequest);
      } catch (refreshError) {
        console.error('Token refresh failed. Stopping retry.');
        SessionStorageService.removeItem(storageKey.token); // Optionally remove invalid token
        // Prevent further retry attempts
        return Promise.reject(refreshError);
      }
    }

    // For other errors or if retry fails, reject the error
    return Promise.reject(error);
  }
);

export default instance;


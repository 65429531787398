import React, { useEffect, useRef, useState } from 'react';
import CameraIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import styles from './ImageUploader.module.scss'; // Import the CSS module
import axios from 'axios';
import { useLoader } from '../../../context/LoaderContext';
import { VIN_PATTERN } from '../../../constants/patterns';
import CustomInput from '../../../components/CustomInput';
import imageCompression from 'browser-image-compression';

interface FileDetail {
    wp_question_id: string;
    assessment_file_id: string | null;
    tags: Array<{ tag_type: string; value: string }>;
    label: string;
    is_claimed: boolean;
    sequence_id: string | null;
    image_uri: string | null;  // This will now be passed from the parent
    onDeleteImage: (id: string) => Promise<void>;
    onUploadImage: (id: string, file: File, vinNumber?: string) => Promise<void>;
    onBase64Preview: (id: string, base64String: string | null) => void;  // Base64 preview handler, allow clearing
    isMinorChip?: boolean;
    vinNumber?: string | null;
    onVinChange?: (id: string, vin: string) => Promise<void>; // Function to trigger VIN API call
    google_vision_api_key?: string;
    overlay_url?: string;
    isVin?: boolean;
}

const ImageUploader: React.FC<FileDetail> = ({
    wp_question_id,
    label,
    image_uri,  // Receive image URI directly from parent
    onDeleteImage,
    onUploadImage,
    onBase64Preview,  // Handler to pass base64 preview to the parent
    isMinorChip,
    vinNumber = '',  // Default to an empty string if VIN is not passed
    onVinChange,
    google_vision_api_key, // Receive the VIN change handler from parent
    overlay_url,
    isVin
}) => {
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [vin, setVin] = useState<string>(vinNumber || "");  // State to manage VIN input
    const { showLoader, hideLoader } = useLoader();
    const debounceTimer = useRef<NodeJS.Timeout | null>(null);
    // const [validationError, setValidationError] = useState<string | null>(null); // State for validation error message

    // Update the local vin state whenever vinNumber changes from the parent
    useEffect(() => {
        setVin(vinNumber || "");  // Sync the vin state with the parent
    }, [vinNumber]);



    // Function to convert file to base64
    const convertToBase64 = (file: File) => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };


    // VIN Extraction Function
    const getVinNo = (text: string) => {
        if (text) {
            const validateRegex = VIN_PATTERN; // VIN numbers are typically 17 characters
            const vinText = text
                .split("\n")  // Split text into lines
                .join(" ")    // Join all lines into a single string
                .split(" ")   // Split by spaces to separate words
                .filter((e) => validateRegex.test(e)); // Filter words that match the VIN pattern
            // Return the first match or null if no matches
            return vinText.length ? vinText[0] : null;
        } else {
            return null;
        }
    };


    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setError(null);  // Reset any previous error
            setUploading(true);  // Indicate that the upload is in progress

            try {
                showLoader();
                // Compress the image to max resolution of 1920x1080
                const options = {
                    maxWidthOrHeight: 1080,  // Max height/width in px
                    useWebWorker: true,      // Use web worker for compression
                };

                // Compress the file
                const compressedFile = await imageCompression(file, options);
                hideLoader();
                // Convert the compressed file to base64 and send it to the parent for preview
                const base64String = await convertToBase64(compressedFile);
                onBase64Preview(wp_question_id, base64String);  // Send base64 preview to the parent

                // If this is a VIN image, detect VIN before upload
                if (isVin) {
                    let detectedVin = vin;  // Default to the current vinNumber
                    showLoader();
                    detectedVin = await handleVinDetection(base64String);  // Detect VIN
                    await onUploadImage(wp_question_id, compressedFile, detectedVin || "");
                } else {
                    await onUploadImage(wp_question_id, compressedFile);  // Upload the compressed image
                }
            } catch (uploadError) {
                console.error('Error uploading image:', uploadError);
                setError('Failed to upload image.');  // Set the error state
                onBase64Preview(wp_question_id, null);  // Clear the base64 preview if upload fails
            } finally {
                setUploading(false);  // Reset the uploading state
            }
        }
    };



    const handleVinDetection = async (base64String: string): Promise<string> => {
        // Check if the image is SVG; if so, reject it.
        if (base64String.startsWith("data:image/svg+xml;base64,")) {
            setVin("");
            // setValidationError("VIN No. couldn’t be detected Enter it manually");
            hideLoader();
            return "";  // Return empty string for invalid SVG
        }
        // Remove the base64 URL prefix to get the pure base64 string for PNG or JPEG
        const cleanBase64String = base64String.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');

        // Proceed with your existing request setup
        const request = {
            requests: [
                {
                    image: { content: cleanBase64String },  // Use cleaned base64 string here
                    features: [
                        {
                            type: "TEXT_DETECTION",  // Detect text
                            maxResults: 4,
                        },
                    ],
                },
            ],
        };

        const postConfig = {
            url: "v1/images:annotate",
            method: "post",
            params: {
                key: google_vision_api_key,
            },
            baseURL: "https://vision.googleapis.com/",
            headers: { "Content-Type": "application/json" },
            data: request,
        };

        try {
            const response = await axios.request(postConfig);
            if (response.data.responses[0].hasOwnProperty("fullTextAnnotation")) {
                const detectedText = response.data.responses[0].fullTextAnnotation.text;
                const vinNo = getVinNo(detectedText) || "";
                // setValidationError(null);
                setVin(vinNo);  // Update the state
                return vinNo;   // Return the detected VIN
            } else {
                // setValidationError("VIN No. couldn’t be detected Enter it manually");
                return "";  // Return empty string if no VIN is detected
            }
        } catch (error) {
            setVin("");
            // setValidationError("VIN No. couldn’t be detected Enter it manually");
            return "";  // Return empty string on error
        } finally {
            hideLoader();
        }
    };

    const handleDelete = async () => {
        try {
            await onDeleteImage(wp_question_id);  // Trigger delete in parent
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };




    // In your VIN input handler, ensure logging and state updates
    // const handleVinInputChange = (newVin: string) => {

    //     // Clear any validation errors if VIN is valid
    //     if (VIN_PATTERN.test(newVin)) {
    //         setValidationError(null);
    //     } else {
    //         setValidationError("Invalid VIN format");
    //     }

    //     // Trigger the parent handler to update state
    //     if (onVinChange) {
    //         onVinChange(wp_question_id, newVin); // Trigger the parent handler
    //     }

    //     setVin(newVin); // Update local state
    // };




    // Handle the blur event to check if the field is touched and empty
    // const handleBlur = () => {
    //     // If the VIN is empty and touched, show required error
    //     if (vin === "") {
    //         setValidationError("VIN is required.");
    //         if (onVinChange) {
    //             onVinChange(wp_question_id, ""); // Trigger the parent handler
    //         }
    //     }
    // };

    return (
        <div className={`${styles.imageUploaderOuter} ${isMinorChip ? styles.imageUploaderOuterMinor : ''} ${isVin ? styles.imageUploaderOuterVIN : ''}`}>
            {error && <p className="custom_error">{error}</p>} {/* Show error message if any */}



            <div className={styles.imageUploaderInner}>
                {!image_uri ? (
                    <>
                        <div className={styles.overlay_part}>
                            <div className={styles.overlay_part_inner}>
                                <img src={isMinorChip ? "/images/minor_chip_overlay.png" : overlay_url} alt="" />
                            </div>
                        </div>
                        <div className={styles.capture_part}>
                            {!uploading && (
                                <div className={styles.capture_part_inner}>
                                    <CameraIcon className={styles.camera_icon} />
                                    <input type="file" accept="image/jpeg, image/jpg, image/png" onChange={handleFileChange} capture="environment" />
                                </div>
                            )}
                            <p className="m-0">{uploading ? 'Uploading...' : 'Capture'}</p>
                        </div>
                    </>
                ) : (
                    <div className={styles.image_previewer}>
                        <img src={image_uri} alt={label} />
                        <CloseIcon onClick={handleDelete} className={styles.close_icon} color="error" />
                    </div>
                )}
            </div>
            {label && !isMinorChip && (
                <div className={styles.part_label}>
                    <p className="m-0">{label}</p>
                </div>)}
            {isMinorChip && (
                <div className={styles.minor_chip_info_section}>
                    <div className={styles.info_icon_part}>
                        <InfoIcon color='primary' className={styles.info_icon} />
                    </div>
                    <div className={styles.info_text_part}>
                        <p className="m-0">kindly, take a close up picture of the minor chip damages
                            on the glass. As shown above  </p>
                    </div>
                </div>
            )}


            {/* {isVin && (
                <div className={styles.vin_input_outer}>
                    <CustomInput
                        label=""
                        value={vin && image_uri ? vin : ''}
                        onChange={(e) => handleVinInputChange(e.target.value)}
                        placeholder="Enter VIN"
                        type="text"
                        onBlur={handleBlur} disabled={!image_uri} // Handle blur for validation
                    />
                    {image_uri && validationError && (
                        <p className="custom_error">{validationError}</p> // Show validation error message in red
                    )}
                </div>
            )} */}

        </div>
    );
};

export default ImageUploader;

import { STATUS, storageKey } from "../constants/constants";


export const getSnackbarMessage = (
  error?: any,
  customMessage?: string
): string => {
  let message: string = error?.response?.data?.error_message || "";
  if (customMessage) message = customMessage;
  if (error && !message) {
    switch (error.status) {
      case 500:
        message = "Internal Server Error ";
        break;
      case 502:
      case 503:
        message = "Server unavailble temporary ";
        break;
      case 401:
      case 403:
        message = "UNAUTHORIED";
        break;
      case 400:
        message = "BAD REQUEST ";
        break;
      case 404:
        message = "NOT FOUND ";
        break;
    }
  }
  if (message === "")
    message = "Something went wrong, please try after sometime ";
  return message;
};

export const arraysAreEqual = (arr1: string[], arr2: string[]) => {
  // Check if lengths are equal
  if (arr1.length !== arr2.length) return false;

  // Compare each element in order
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const getOTP = (): string => {
  // Get item from sessionStorage and ensure any extra quotes are removed
  const otp = sessionStorage.getItem(storageKey.otp);
  return otp ? otp.replace(/"/g, '') : ""; // Return an empty string if the OTP is not found
};

export const getCallbackURL = (): string => {
  // return sessionStorage.getItem(storageKey.callbackURL) || "";
  const callbackURL = sessionStorage.getItem(storageKey.callbackURL);
  return callbackURL ? callbackURL.replace(/"/g, '') : ""; // Return an empty string if the OTP is not found
};


export const nagivateAfterExpiry = () => {
  window.location.href = `/error?mode=${STATUS.EXPIRED}`; // Redirects to error page
}
import instance from "../utils/interceptor";
import { endpoint } from "./constants";

const api = {
  verifyToken: (id: string, lat: string | null, long: string | null) => {
    const payload = {
      lat, long
    }
    const url: string = endpoint.verifyToken(id);
    return instance.post(url, payload, { headers: { "User-Agent": navigator.userAgent } });
  },
  refreshTokenAccess: (payload: {}) => {
    const url: string = endpoint.refreshTokenAccess;
    return instance.post(url, payload);
  },
  getDynamicLinkDetailsDetails: (id: string) => {
    const url: string = endpoint.getDynamicLinkDetailsDetails(id);
    return instance.get(url);
  },
  initiateManualUpload: (payload: {}) => {
    const url: string = endpoint.initiateManualUpload;
    return instance.post(url, payload);
  },
  patchManualUpload: (manual_upload_id: string, payload: {}) => {
    const url: string = endpoint.patchManualUpload(manual_upload_id);
    return instance.patch(url, payload);
  },
  uploadImage: (manual_upload_id: string, payload: FormData) => {
    const url: string = endpoint.uploadImage(manual_upload_id);
    return instance.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure the content type is set correctly
      }
    });
  },
  deleteImage: (manual_upload_id: string, question_id: string, additional_image_id?: string) => {
    let url: string = endpoint.deleteImage(manual_upload_id, question_id);
    if (additional_image_id) {
      url += `?additional_image_id=${additional_image_id}`
    }
    return instance.delete(url);
  }
};

export default api;

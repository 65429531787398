export const SessionStorageService = {
    setItem: <T>(key: string, value: T): void => {
        sessionStorage.setItem(key, JSON.stringify(value));
    },
    getItem: <T>(key: string): T | null => {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    },
    removeItem: (key: string): void => {
        sessionStorage.removeItem(key);
    },
    clear: (): void => {
        sessionStorage.clear();
    }
};
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { ApiContext } from '../../context/ApiContext';
import { useLoader } from '../../context/LoaderContext';
import { SessionStorageService } from '../../utilities/SessionStorage';
import { CAR_SUB_AREA_MAPPED_LIST, STATUS, TAG_TYPE, storageKey } from '../../constants/constants';
import api from '../../api/api';
import { CarSubAreaMappedData, DynamicLinkData, FileDetail } from '../../constants/dataTypes';
import { isLoggedIn } from '../../utilities/Auth';
import { getCallbackURL } from '../../utilities/GenericService';

const Journey: React.FC<{ onSetTheme: (theme: string) => void }> = ({ onSetTheme }) => {
  const [isLoading, setIsLoading] = useState(true); // Track loading state
  const navigate = useNavigate(); // For redirecting to other routes
  const location = useLocation();
  const { otp } = useParams<{ otp: string }>(); // Access the 'otp' param from the route
  const { settoken, setApiResponse } = useContext(ApiContext); // Access the context to store token

  // Extract token from query params, fallback to sessionStorage if not in URL
  const params = new URLSearchParams(location.search);
  const tokenFromQuery = params.get(storageKey.token);
  const refreshTokenFromQuery = params.get(storageKey.refreshToken);
  const userId = params.get(storageKey.userId);
  const callbackURL = params.get(storageKey.callbackURL);
  const id = params.get('_id');
  const token = tokenFromQuery || SessionStorageService.getItem(storageKey.token); // Use sessionStorage token if query param is missing
  const { showLoader, hideLoader } = useLoader();


  const subAreaWithImage: CarSubAreaMappedData[] = JSON.parse(JSON.stringify(CAR_SUB_AREA_MAPPED_LIST));


  useEffect(() => {
    showLoader();
    if (!token || !id) {
      navigate('/error', { replace: true }); // Redirect to error page with replace
      hideLoader();
      return;
    }

    // Store the token in sessionStorage (if it's from query params)
    if (tokenFromQuery) {
      SessionStorageService.clear();
      SessionStorageService.setItem(storageKey.token, tokenFromQuery);
      SessionStorageService.setItem(storageKey.refreshToken, refreshTokenFromQuery);
      SessionStorageService.setItem(storageKey.userId, userId);
      SessionStorageService.setItem(storageKey.otp, otp);
      if (callbackURL) {
        SessionStorageService.setItem(storageKey.callbackURL, callbackURL);
      }
    }

    const getLocation = (): Promise<{ lat: number | null, long: number | null }> => {
      return new Promise((resolve) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const lat = position.coords.latitude;
              const long = position.coords.longitude;
              resolve({ lat, long });
            },
            (error) => {
              console.warn("Geolocation error:", error.message);
              // Resolve with null values in case of error
              resolve({ lat: 0.000, long: 0.000 });
            }
          );
        } else {
          console.warn('Geolocation is not supported by this browser.');
          resolve({ lat: 0.000, long: 0.000 }); // Resolve with null values if geolocation is not supported
        }
      });
    };



    // Make the API call
    const verifytoken = async () => {
      try {
        setIsLoading(true); // Start loading
        const { lat, long } = await getLocation(); // Get the location before the API call
        await api.verifyToken(id || "", lat?.toString() || "0.000", long?.toString() || "0.000");
        const payload: { lat: string, long: string } = { lat: lat?.toString() || "0.000", long: long?.toString() || "0.000" }; // Create the payload with location
        getDynamicLinkDetails(payload);
      } catch (err: any) {
        // navigate(`/status/${id}?mode=expired`, { replace: true }); // Redirect to error page only in case of failure

        if (err?.status >= 400 && err?.status < 500) {
          navigate(`/status/${id}?mode=${STATUS.EXPIRED}`, { replace: true });
        } else {
          navigate(`/error?mode=${STATUS.EXPIRED}`, { replace: true }); // Redirect to error page only in case of failure
        }


        hideLoader();
        setIsLoading(false); // Stop loadings
      } finally {
      }
    };

    verifytoken();
  }, [token, id, navigate, settoken, setApiResponse, tokenFromQuery]);


  const getDynamicLinkDetails = async (browser_location: { lat: string, long: string }) => {
    try {
      showLoader();
      // setIsLoading(true); // Start loading
      const response = await api.getDynamicLinkDetailsDetails(id || "");
      // const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/`, {})
      const result: DynamicLinkData = response.data;
      result.google_vision_api_key = response?.data?.google_vision_api_key || "";

      // Set the theme dynamically
      onSetTheme('theme1');
      // Check the file_status first
      if (result.status === STATUS.MANUAL_SUBMIT || result.status === STATUS.FILE_UPLOAD_DONE) {
        const callbackURL = getCallbackURL();
        if (callbackURL) {
          // Replace the current history state to prevent back navigation
          window.history.replaceState(null, "", window.location.href);
          // Use window.location.replace to prevent adding a new entry to history
          window.location.replace(callbackURL);
          return;
        } else {
          // Navigate to the status page (replace: true will ensure the previous page is not kept in history)
          navigate(`/status/${id}?mode=${STATUS.SUBMITTED}`, { replace: true });
          return;
        }
      }
      let AREA: string = "";
      // Filter file details for claimed and mandatory fields
      const claimed_fields = result.file_details.filter((file) => file.is_claimed === true);
      const mandatory_fields = result.file_details.filter((file) => file.is_claimed === false);
      // Group the claimed objects by AREA and SUB_AREA
      const groupedClaimed: any = claimed_fields.reduce((acc: any, item) => {
        const areaTag: any = item.tags.find(tag => tag.tag_type === TAG_TYPE.AREA);
        const subAreaTag: any = item.tags.find(tag => tag.tag_type === TAG_TYPE.SUB_AREA);

        const area: string | undefined = areaTag?.value;
        const subArea: string | undefined = subAreaTag?.value;

        AREA = area || "";


        if (area && subArea) {
          if (!acc[area]) {
            acc[area] = new Set(); // Use Set to avoid duplicates
          }
          acc[area].add(subArea);
        }
        return acc;
      }, {});
      // Transform the grouped data into the desired format (array of areas with their subareas)
      const subArea_list: any[] = Object.keys(groupedClaimed).map((area) => ({
        area,
        subArea: Array.from(groupedClaimed[area]), // Convert Set to Array
      }));
      // Initialize the array for all claim types
      const all_claim_types: any[] = [];
      // Assuming `subAreaWithImage` and `selected_claim_subareas` are provided
      // const selected_data: any[] = result.selected_claim_subareas;
      const selected_data: string[] = result?.claimed_areas?.map(subarea => subarea?.split('|')[1]) || [];
      // Iterate over the subareas for each area in `subArea_list`
      subArea_list.forEach((group) => {
        group.subArea.forEach((element: string) => {
          // Find data in subAreaWithImage based on AREA
          const foundData: any = subAreaWithImage.find((e: any) => e.AREA === element);
          // Create an object for each subArea
          const data = {
            AREA: element,
            label: foundData?.label || '', // Default empty if not found
            overlayUrl: foundData?.overlayUrl || '', // Default empty if not found
            isChecked: selected_data?.includes(element) // Check if selected
          };

          // Push the created data into `all_claim_types`
          all_claim_types.push(data);
        });
      });
      // Define the desired order for sorting
      const desiredOrder = [
        "FRONT_WINDSHIELD",
        "DRIVER_SIDE_FRONT_WINDOW",
        "PASSENGER_SIDE_FRONT_WINDOW",
        "DRIVER_SIDE_REAR_WINDOW",
        "PASSENGER_SIDE_REAR_WINDOW",
        "REAR_WINDSHIELD"
      ];
      // Sort `all_claim_types` based on the `desiredOrder`
      all_claim_types.sort((a, b) => {
        const indexA = desiredOrder.indexOf(a.AREA);
        const indexB = desiredOrder.indexOf(b.AREA);
        // If the label is not in the desired order array, it will be moved to the end
        if (indexA === -1) return 1;
        if (indexB === -1) return -1;
        return indexA - indexB;
      });


      // Final result with claimed_fields, mandatory_fields, and all_claim_types
      const modifiedResult = {
        ...result,
        claimed_fields,
        mandatory_fields,
        all_claim_types,
        otp,
        browser_location,
        AREA
      };
      settoken(token); // Save the token in context
      setApiResponse(modifiedResult); // Save the API response in context
      // Check if all the selected subareas (from step 1) have image_uri
      const selectedAreas = modifiedResult.all_claim_types.filter(claimType => claimType.isChecked);

      if (result?.claimed_areas?.length && !result?.manual_upload_id) {
        try {
          const payload = {
            "claimedArea": result?.claimed_areas,
            "link_id": id,
            "preferred_location": browser_location,
            "save_as_draft": true
          }
          const response = await api.initiateManualUpload(payload);
          setApiResponse((prevResponse: any) => ({
            ...prevResponse,
            manual_upload_id: response?.data?.trim()
          }));
          navigate(`/glass-selection/${id}`);
          navigate(`/damage-capture/${id}`);
          hideLoader();
        } catch (err: any) {
          hideLoader();
          navigate('/error'); // Redirect to error page only in case of failure
          return;
        }
      }

      if (selectedAreas.length === 0) {
        // If no areas are selected, go to glass-selection
        navigate(`/glass-selection/${id}`, { replace: true });
        hideLoader();
        return;
      }

      // Grouping logic to be executed on every render or as required
      const selectedAreasData = all_claim_types
        .filter((claimType: { AREA: string; isChecked: boolean }) => claimType.isChecked)
        .map((claimType: { AREA: string }) => claimType.AREA);


      const selectedFields: FileDetail[] = claimed_fields?.filter((file: FileDetail) => {
        if (!file || !file.tags) return false;
        const subAreaTag = file.tags.find((tag) => tag.tag_type === TAG_TYPE.SUB_AREA);
        return subAreaTag && selectedAreasData.includes(subAreaTag.value);
      });

      const groupedByArea = selectedAreasData?.map((area: any) => {
        const areaFiles: FileDetail[] = selectedFields.filter((file: FileDetail) =>
          file?.tags?.find((tag) => tag.tag_type === TAG_TYPE.SUB_AREA && tag.value === area)
        );

        const files: FileDetail[] = [];
        let minorChipDamage: FileDetail | null = null;


        // areaFiles.forEach((file: FileDetail) => {
        //   // Check if this file matches an already added file based on template_body_value
        //   const existingFileIndex = files.findIndex(
        //     (f: FileDetail) => f.template_body_value === file.template_body_value
        //   );

        //   // If no minor chip damage has been assigned yet and it's a duplicate, assign it as the minor chip damage
        //   if (existingFileIndex !== -1 && !minorChipDamage) {
        //     // If a match is found and no minor chip has been flagged, this file becomes the minor_chip_damage
        //     minorChipDamage = { ...file }; // Add is_minor flag to this file
        //   } else {
        //     // Either it's the first occurrence of the file, or it's not a duplicate
        //     files.push({ ...file }); // Ensure regular files also have is_minor: false
        //   }
        // });

        areaFiles.forEach((file: FileDetail) => {
          // If the file is marked as minor_chip_damage by the backend
          if (file.is_minor_damage) {
            // Assign it as the minor chip damage
            minorChipDamage = { ...file };
          } else {
            // Otherwise, it's a regular file, so add it to the files array
            files.push({ ...file });
          }
        });


        return {
          AREA: area,
          files: files,
        };
      });


      const missingClaimedImages = groupedByArea.flatMap((group) => {
        // Check for files without image
        const missingFiles = group.files.filter((file) => !file.image_uri);
        return [...missingFiles];
      });
      // If any sub-area is missing images, set allSubAreasHaveImages to false
      if (!missingClaimedImages?.length) {
        navigate(`/glass-selection/${id}`);
        navigate(`/damage-capture/${id}`);
        navigate(`/mandatory-images/${id}`);
        hideLoader();
      } else {
        navigate(`/glass-selection/${id}`);
        navigate(`/damage-capture/${id}`);
        hideLoader();
      }
    } catch (err: any) {
      if (err?.status >= 400 && err?.status < 500 && isLoggedIn()) {
        navigate(`/status/${id}?mode=${STATUS.EXPIRED}`, { replace: true });
      } else {
        navigate('/error'); // Redirect to error page only in case of failure
      }
      hideLoader();
    } finally {
      hideLoader();
      setIsLoading(false); // Stop loading
    }
  }

  return null; // Since we are redirecting to another page, no need to render anything here
};

export default Journey;


import React, { createContext, useState, ReactNode } from 'react';

// Create the context
export const ApiContext = createContext<any>(null);

interface ApiProviderProps {
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const [token, settoken] = useState<string | null>(null); // token state
  const [apiResponse, setApiResponse] = useState<any>(null); // Store API response if needed

  return (
    <ApiContext.Provider value={{ token, settoken, apiResponse, setApiResponse }}>
      {children}
    </ApiContext.Provider>
  );
};

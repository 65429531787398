import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import styles from './Status.module.scss'; // Import the CSS Module
import { STATUS } from '../../constants/constants';

const Status: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Parse the query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');

  // Prevent back navigation using the history API
  useEffect(() => {
    // Replace the current entry to prevent back navigation
    navigate(location.pathname + location.search, { replace: true });

    // Add a popstate listener to intercept the back button
    const handleBackButton = (event: PopStateEvent) => {
      // Replace the current entry again or redirect to another route to block back navigation
      navigate(location.pathname + location.search, { replace: true });
    };

    window.addEventListener('popstate', handleBackButton);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [navigate, location.pathname, location.search]);

  return (
    <MainContainer
      headerText={mode === STATUS.SUBMITTED ? 'Journey completed' : mode === STATUS.EXPIRED ? 'Link Expired' : 'Error'}
    >
      <section className={styles.status_container_outer}>
        <div className={styles.status_container_inner}>
          <div className={styles.img_part}>
            <img
              src={mode === STATUS.SUBMITTED ? '/images/status/submitted.svg' : '/images/status/expired.svg'}
              alt=""
            />
          </div>
          <div className={styles.text_part}>
            <div className={styles.main_text}>
              <p className="m-0">
                {mode === STATUS.SUBMITTED
                  ? 'Inspection Completed'
                  : mode === STATUS.EXPIRED
                    ? 'The link has expired'
                    : 'Something went wrong, please contact helpdesk!'}
              </p>
            </div>
            {(mode === STATUS.SUBMITTED || mode === STATUS.EXPIRED) && (
              <div className={styles.sub_text}>
                <p className="m-0">
                  {mode === STATUS.SUBMITTED
                    ? "Thank you! Your photos have been submitted for review. Our team is now inspecting the damage. We will contact you if additional information is needed. You can expect further communication soon regarding your claim status."
                    : "The link you're trying to access has expired."}
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

    </MainContainer>
  );
};

export default Status;
